import React from "react";
import { FeaturePanel } from "../content-elements";
import { StaticImage } from "gatsby-plugin-image";

const CashFlow = () => {
    return (
      <FeaturePanel 
        description="Get a high-level overview with monthly summaries of your income and expenses"
        title="Cash flow"
      >
        <StaticImage
          src="./images/cash-flow-illustration.png"
          alt=""
          width={315}
          quality={100}
          placeholder="none"
        />
      </FeaturePanel>
    )
}

export default CashFlow
