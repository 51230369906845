import { H3, H5 } from "../../shared/headings";
import styled, { css } from "styled-components";

import { Link } from "gatsby";
import React from "react";

export const Description = styled(H3)`
    padding-top: 1rem;
    padding-bottom: 40px;
    order: 1;
    ${ props => props.theme.device.mobileMd } {
        grid-column: col-start 2 / span 10; 
    } 
    ${ props => props.theme.device.tablet } {
        padding-top: ${props => props.padTop ? props.padTop : '22%'};
        padding-bottom: 0;
        order: ${props => props.reversed ? 1 : 2};
        grid-column: ${props => props.reversed ? 'col-start 2 / span 4' : 'col-start 8 / span 4'}; 
    }
`
export const ImageArea = styled.div`
    text-align: center;
    grid-column: col-start / span 12;
    order: 2;
    max-width: 96%;
    margin: 0 auto;
    ${ props => props.theme.device.tablet } {
        order: ${props => props.reversed ? 2 : 1};
        grid-column: ${props => props.reversed ? 'col-start 6 / span 7' : 'col-start / span 7'};
        max-width: none;
    }
`

export const FeaturePanel = ({title, description, children, hideBg, link, outbound, linkTitle, imageStyle, comingSoon, ...props}) => {
    return (
      <StyledFeaturePanel hideBg={hideBg} {...props}>
        <Image style={imageStyle}>{children}</Image>
        <div>
          <H5>{title}</H5>
          <p>{description}</p>
          {link && linkTitle && <UnderlinedLink to={link} title={linkTitle} outbound={outbound} />}
          {comingSoon && <h6 className="muted" style={{ fontSize: 12, marginTop: 5 }}>COMING SOON</h6>}
        </div>
      </StyledFeaturePanel>
    )
}

export const FeatureCallout = ({title, description, Icon, ...props}) => {
    return (
      <StyledFeatureCallout {...props}>
        <div>
          <Icon/>
          <div>
            <H5>{title}</H5>
            <p>{description}</p>
          </div>
        </div>
      </StyledFeatureCallout>
    )
}

export const DoubleFeature = styled.div`
  display: grid;
  margin: 16px 0;
  grid-template-columns: 1fr;
  grid-column: span 12;
  gap: 24px;
  ${props => props.theme.device.tablet} {    
    gap: 16px;
    grid-template-columns: 1fr 1fr;
    grid-column: col-start 2 / span 10;
  }
  ${props => props.theme.device.laptop} {    
    gap: 48px;
    grid-template-columns: 1fr 1fr;
    grid-column: col-start 3 / span 8;
  }
`

export const SingleFeature = styled.div`
  display: grid;
  margin: 16px 0;
  grid-template-columns: 1fr;
  grid-column: span 12;
  gap: 24px;
  ${props => props.theme.device.tablet} {
    gap: 16px;
    grid-template-columns: 1fr;
    grid-column: col-start 2 / span 10;
  }
  ${props => props.theme.device.laptop} {
    gap: 48px;
    grid-template-columns: 1fr;
    grid-column: col-start 5 / span 4;
  }
`

const bg = css`
    border: 1px solid rgba(48,137,255,0.1);
    background: radial-gradient(94.91% 94.91% at 50% 50%,rgba(1, 69, 137, 0.22) 0%, rgba(0, 0, 0, 0) 100%), rgba(5,24,48,0.2);
    // box-shadow: 0px -40px 76px -40px rgba(38, 91, 181, 0.1), 0px 4px 76px -160px rgba(106, 167, 248, 0.3);
`
  
const StyledFeaturePanel = styled.div`
    text-align: center;
    & h5 {
        max-width: 260px;
        margin: 0 auto;
    }
    & p {
        font-weight: 600;
        color: ${props => props.theme.color.textSecondary};
        max-width: 260px;
        margin: 0 auto;
    }
    & h6.muted {
        text-transform: none;
        color: ${props => props.theme.color.textLink};
        letter-spacing: 0.4px;
    }
    ${props => props.theme.device.laptopLg} {
        border-radius: 10px;
        overflow: hidden;
        padding-bottom: 70px;
        ${props => !props.hideBg && bg}
    }
`

export const SymbolPanel = ({symbol, company, path, change}) => {
    return (
        <StyledSymbolPanel>
            <Symbol>{symbol}</Symbol>
            <Company>{company}</Company>
            <Sparkline viewBox="0 0 190 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d={path} stroke="#2BB61A" strokeWidth="3.33333" strokeLinecap="round" strokeLinejoin="round"/>
            </Sparkline>
            <PerformancePill>
                <ChangeAmount>{change}</ChangeAmount>
            </PerformancePill>
        </StyledSymbolPanel>
    )
}



const StyledSymbolPanel = styled.div`
    text-align: left;
    width: 130px;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 6px;
    background: radial-gradient(94.91% 94.91% at 50% 50%,rgba(1, 69, 137, 0.22) 0%, rgba(0, 0, 0, 0) 100%), rgba(5,24,48,0.2);
    // box-shadow: 0px -40px 76px -40px rgba(38, 91, 181, 0.1), 0px 4px 76px -160px rgba(106, 167, 248, 0.3);
    overflow: hidden;
    border: 1px solid rgba(48,137,255,0.1);
    ${props => props.theme.device.tablet} {
        width: 190px;
        border-radius: 10px;
    }
`
const Symbol = styled.div`
    padding: 12px 12px 0px 12px;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    color: ${props => props.theme.color.textPrimary};
    ${props => props.theme.device.tablet} {
        padding: 20px 20px 0px 20px;
        font-size: 20px;
        line-height: 25px;
    }
`
const Company = styled.div`
    padding: 0px 12px 12px 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${props => props.theme.color.textPrimary};
    ${props => props.theme.device.tablet} {
        padding: 0px 20px 20px 20px;
        font-size: 23.3333px;
        line-height: 29px;
    }
`
const Sparkline = styled.svg`
    width: 100%;
`

export const PerformancePill = styled.div`
    background: #032625;
    border-radius: 24px;
    padding: 2px 12px 4px 12px;
    margin: 16px auto 12px auto;
    text-align: center;
    ${props => props.theme.device.tablet} {
        padding: 3px 20px 6px 16px;
        margin: 24px auto 20px auto;
    }
`
export const ChangeAmount = styled.div`    
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.005em;
    color: #0AB101;
    &:before {
        content: url("data:image/svg+xml,%3Csvg width='9.1' height='10.4' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.16048 15.9775C8.01497 15.9775 8.5765 15.3835 8.5765 14.4883V6.35026L8.50325 4.59245L9.70768 6.03288L11.5306 7.85579C11.791 8.12435 12.1084 8.31966 12.5479 8.31966C13.3047 8.31966 13.8825 7.76628 13.8825 6.95247C13.8825 6.58626 13.736 6.22819 13.443 5.94336L8.20215 0.694336C7.94987 0.442057 7.54297 0.279297 7.16048 0.279297C6.77799 0.279297 6.37109 0.442057 6.11881 0.694336L0.87793 5.94336C0.593099 6.22819 0.438477 6.58626 0.438477 6.95247C0.438477 7.76628 1.01628 8.31966 1.78125 8.31966C2.21256 8.31966 2.53809 8.12435 2.7985 7.85579L4.60514 6.03288L5.81771 4.59245L5.73633 6.35026V14.4883C5.73633 15.3835 6.30599 15.9775 7.16048 15.9775Z' fill='%230AB101'/%3E%3C/svg%3E%0A");
        width: 13.44px;
        height: 15.7px;
        margin-right: 4px;
        ${props => props.theme.device.tablet} {
            content: url("data:image/svg+xml,%3Csvg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.16048 15.9775C8.01497 15.9775 8.5765 15.3835 8.5765 14.4883V6.35026L8.50325 4.59245L9.70768 6.03288L11.5306 7.85579C11.791 8.12435 12.1084 8.31966 12.5479 8.31966C13.3047 8.31966 13.8825 7.76628 13.8825 6.95247C13.8825 6.58626 13.736 6.22819 13.443 5.94336L8.20215 0.694336C7.94987 0.442057 7.54297 0.279297 7.16048 0.279297C6.77799 0.279297 6.37109 0.442057 6.11881 0.694336L0.87793 5.94336C0.593099 6.22819 0.438477 6.58626 0.438477 6.95247C0.438477 7.76628 1.01628 8.31966 1.78125 8.31966C2.21256 8.31966 2.53809 8.12435 2.7985 7.85579L4.60514 6.03288L5.81771 4.59245L5.73633 6.35026V14.4883C5.73633 15.3835 6.30599 15.9775 7.16048 15.9775Z' fill='%230AB101'/%3E%3C/svg%3E%0A");
            width: 13.44px;
            height: 15.7px;
            margin-right: 8px;
            scale: 1;
        }
    }
    &:after {
        content: "%";
    }
    ${props => props.theme.device.tablet} {
        font-size: 23.3333px;
        line-height: 29px;
    }
`
const Image = styled.div`
    margin-top: 40px;
    margin-bottom: 30px;
    display: block;
    position: relative;
    bottom: 0;
    height: 240px;
    ${props => props.theme.device.tablet} {
        margin-top: 78px;
        // height: clamp(11.25rem, 8.052rem + 10.234vw, 15.625rem);
    }
    ${props => props.theme.device.laptopLg} {
        height: 280px;
    }
    &:before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 203px;
        height: 73px;
        top: 0;
        left: calc(50% - 203px/2);
        // background: #000810;
        // filter: blur(55px);
        // mix-blend-mode: screen;
    }
`

export const StyledFeatureCallout = styled.div`
    width: 48%;
    margin: 0 auto;
    display: flex;
    place-items: center;
    flex-direction: column;
    text-align: center;
    & h5 {
        max-width: 206px;
        font-size: 0.8125rem;
        margin-top: 4px;
    }
    & p {
        font-weight: 600;
        color: ${props => props.theme.color.textSecondary};
        max-width: 206px;
        font-size: 0.8125rem;
    }
    & svg {
        width: 24px;
        height: 24px;
    }
    ${props => props.theme.device.tablet} {
        text-align: left;
        width: 31%;
        margin-bottom: 64px;
    }
    ${props => props.theme.device.laptop} {
        // margin-bottom: 100px;
        & svg {
            width: 36px;
            height: 36px;
        }
        & h5 {
            font-size: ${props => props.theme.fontSize.regular};
            margin-top: 16px;
        }
        & p {
            font-size: ${props => props.theme.fontSize.regular};
        }
    }
    ${props => props.theme.device.laptopLg} {
        margin-bottom: 100px;
    }
    margin-bottom: 32px;
`

export const UnderlinedLink = ({ to, title, outbound }) => {
    if (outbound) {
        return (
            <StyledOutboundLink href={to}>
                <LinkContent>{title}</LinkContent>
            </StyledOutboundLink>
        )
    } else {
        return (
            <StyledLink to={to}>
                <LinkContent>{title}</LinkContent>
            </StyledLink>
        )
    }
}

const LinkContent = styled.div`
    color: ${props => props.theme.color.textLink};
    display: inline-block;
    position: relative;
    margin: 0.5rem auto;
    padding-bottom: 0.1em;
    text-align: center;
    font-weight: 600;
    font-size: ${props => props.theme.fontSize.regular};
    overflow: hidden;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.1em;
        background-color: ${props => props.theme.color.textLink};
        opacity: 1;
        transform: translate3d(-101%, 0, 0);
        transition: opacity 300ms, transform 300ms;
    }
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.1em;
        background-color: ${props => props.theme.color.textLink};
        opacity: 0.3;
        transform: translate3d(0, 0, 0);
    }
    & svg {
        color: ${props => props.theme.color.textLink};
        width: 1.5em;
        margin: 0.5em auto;
        display: block;
        opacity: 0.3;
        transition: color 300ms, opacity 300ms;
    }
`

const StyledLink = styled(Link)`
    grid-column: col-start / span 12;
    place-self: center;
    max-width: 300px;
    text-align: center;
    margin-top: 1rem;
    &:hover, &:focus {
        & ${LinkContent} {
            &::after {
                transform: translate3d(0, 0, 0);
            }
            & svg {
                opacity: 1;
                color: ${props => props.theme.color.textLink};
            }
        }
    }
`

const StyledOutboundLink = styled.a`
    grid-column: col-start / span 12;
    place-self: center;
    max-width: 300px;
    text-align: center;
    margin-top: 1rem;
    &:hover, &:focus {
        & ${LinkContent} {
            &::after {
                transform: translate3d(0, 0, 0);
            }
            & svg {
                opacity: 1;
                color: ${props => props.theme.color.textLink};
            }
        }
    }
`