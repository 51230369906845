import React from "react";
import styled from "styled-components";
import { H5 } from "./headings";
import { Link } from "gatsby";
 
const Panel = ({ title, bgColor, link, fullHeight, children, ...props }) => {
    const label = link?.label || undefined
    const url = link?.url || undefined
    return (
        <StyledPanel
            as={link ? Link : "div"} // render an anchor tag instead of a div if a link is passed
            to={link && url}
            bg={bgColor}
            fullHeight={fullHeight}
            {...props}
        >
            <H5>{title}</H5>
            {label && (
                <LinkLabel>{label}</LinkLabel>
            )}
            {children}
        </StyledPanel>
    )
}

const LinkLabel = styled(H5)`
    display: inline-block;
    position: relative;
    padding-bottom: 0.1em;
    color: ${props => props.theme.color.textLink};
    overflow: hidden;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.1em;
        background-color: ${props => props.theme.color.textLink};
        opacity 1;
        transform: translate3d(-101%, 0, 0);
        transition: opacity 300ms, transform 300ms;
    }
`
const StyledPanel = styled.div`
    border-radius: 10px;
    background-color: ${props => props.bg ? props.bg : `rgba(93, 118, 150, 0.2)`};
    padding: 18px 20px 20px 20px;
    grid-column: col-start / span 12;
    overflow: hidden;
    padding-bottom: ${props => props.fullHeight ? 0 : 20};
        text-decoration: none;
    ${props => props.theme.device.tablet} {
        grid-column: span 6;
    }
    ${props => props.theme.device.laptopLg} {
        padding-bottom: ${props => props.fullHeight ? 0 : 32};
    }
    transition: background-color 300ms;
    &:hover, &:focus {
        background-color: ${props => props.href && '#5308A1'};
        & ${LinkLabel} {
            &::after {
                transform: translate3d(0, 0, 0);
            }
        }
    }
`

export default Panel