import { Percent } from "./panel-content";
import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

const Category = ({ label, emoji, color, performance, asHeader, animDelay }) => {

  const barWidth = performance ? performance*0.9 : 0;
  let perfLabel;
  if (performance) {
    if (performance < 50) {
      perfLabel = 100 - (performance * 2);
    } else {
      perfLabel = performance - 50;
    }
  }

  const barStates = {
    initial: {
      width: "2%",
      borderRadius: 6
    },
    final: {
      width: `${barWidth}%`,
      borderRadius: 6
    }
  }
  const Bar = (props) => {
    return (
      <BarBase>
        <div style={{display: "flex"}}>
          <StyledBar 
            color={props.color} 
            variants={barStates}
            initial="initial"
            animate="final"
            transition={{ duration: 1, ease: "easeInOut", delay: animDelay }}
          />
        </div>
        <span className="emoji">{props.emoji}</span>
        <span className="label">{props.label}</span>
        <span className="value">
          <ConditionalWrapper
            condition={props.performance !== 50}
            wrapper={children => <Percent>{children}</Percent>}
          >
            <PerformanceIndicator performance={props.performance}/> {props.performance !== 50 && perfLabel}
          </ConditionalWrapper>
        </span>
      </BarBase>
    )
  }
  const Pill = (props) => {
    return (
      <ConditionalWrapper 
        condition={props.asHeader} 
        wrapper={children => <CategoryHeader>{children}</CategoryHeader>}
      >
        <PillBase color={props.color}>
          <span className="emoji">{props.emoji}</span>
          <span className="label">{props.label}</span>
        </PillBase>
      </ConditionalWrapper>
    )
  }

  return (
    <>
    {performance ? (
      <Bar 
        label={label} 
        emoji={emoji} 
        color={color} 
        performance={performance}
      />
    ) : (
      <Pill 
        label={label} 
        emoji={emoji} 
        color={color} 
        asHeader={asHeader} 
      />
    )}
    </>
  )
}

const StyledBar = styled(motion.div)` 
  display: inline-block;
  position relative;
  top: 0;
  height: 36px;
  width: 100%;
  background-color: ${props => props.color ? props.color : 'blue'};
  width: 1%;
  transform-origin: 0 0;
`
const PillBase = styled.div`
  background-color: ${props => props.color ? props.color : 'blue'};
  height: 28px;
  border-radius: 14px;
  padding: 4.5px 11px 4.5px 9px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  & .emoji {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    width: 21px;
    height: 21px;
    margin-bottom: -2px;
  }
  & .label {
    font-style: normal;
    padding-left: 3.5px;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.58px;
    color: #FFFFFF;
    text-transform: uppercase;
  }
`

const BarBase = styled.div`
  position: relative;
  // width: 100%;
  left: 20px;
  overflow: hidden;
  margin-bottom: 6px;
  border-radius: 4px;
  & span.emoji {
    position: absolute;
    top: 20%;
    font-size: 24px;
    top: 1px;
    left: 16px;
  }
  & span.label {
    position: absolute;
    top: 20%;
    left: 45%;
    transform: translateX(-50%);
  }
  & span.value {
    position: absolute;
    top: 20%;
    right: 32px;
    text-align: center;
    width: 54px;
  }
`
const CategoryHeader = styled.div`
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap; 
  & > ${PillBase} {
    position: relative;
  }
  & > ${PillBase}:before,
  & > ${PillBase}:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1.5px;
    background: #082B55;
    // background-blend-mode: screen;
    // background-blend-mode: plus-lighter;
  }
  & > ${PillBase}:before {
    right: 100%;
    margin-right: 15px;
  }
  & > ${PillBase}:after {
    left: 100%;
    margin-left: 15px;
  }
`

const ConditionalWrapper = ({ condition, wrapper, children }) => 
  condition ? wrapper(children) : children;

const PerformanceIndicator = ({performance}) => {
  let direction;
  if (performance > 50) {
    direction = "up"
  } else if (performance < 50) {
    direction = "down"
  } else {
    direction = "equals"
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="10"
      fill="none"
      viewBox="0 0 9 10"
      style={{ transform: `rotate(${direction === "down" ? 180 : 0}deg)` }}
    >
      {direction === "equals" ? (
        <>
          <path d="M7.80169 7.62H1.17969V6.192H7.80169V7.62Z" fill="#D2DAE4"/>
          <path d="M7.80169 4.428H1.17969V3H7.80169V4.428Z" fill="#D2DAE4"/>
        </>
      ) : (
        <path fill="#fff" d="M3.51 8.349a.75.75 0 001.5 0V2.393l2.249 1.95a.75.75 0 10.982-1.134L4.761.192A.75.75 0 003.76.191L.26 3.208a.75.75 0 00.98 1.136l2.27-1.957v5.962z"></path>
      )}
    </svg>
  );
}
  

export default Category;