import React from "react";

function IconFaceID() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="107"
      height="106"
      fill="none"
      viewBox="0 0 107 106"
    >
      <path
        stroke="#347DEF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="6.096"
        d="M28.495 4h-5.553C16.114 4 12.7 4 10.092 5.329a12.191 12.191 0 00-5.328 5.328c-1.328 2.607-1.328 6.021-1.328 12.85v4.876"
      ></path>
      <path
        stroke="#C9D1E0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="6.096"
        d="M28.495 4h-5.553C16.114 4 12.7 4 10.092 5.329a12.191 12.191 0 00-5.328 5.328c-1.328 2.607-1.328 6.021-1.328 12.85v4.876"
      ></path>
      <path
        stroke="#347DEF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="6.096"
        d="M28.497 102.886h-5.554c-6.828 0-10.242 0-12.85-1.329a12.192 12.192 0 01-5.328-5.328c-1.328-2.608-1.328-6.021-1.328-12.85v-4.876"
      ></path>
      <path
        stroke="#C9D1E0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="6.096"
        d="M28.497 102.886h-5.554c-6.828 0-10.242 0-12.85-1.329a12.192 12.192 0 01-5.328-5.328c-1.328-2.608-1.328-6.021-1.328-12.85v-4.876"
      ></path>
      <path
        stroke="#347DEF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="6.096"
        d="M77.94 4h5.554c6.828 0 10.242 0 12.85 1.33a12.191 12.191 0 015.327 5.327C103 13.265 103 16.68 103 23.507v4.876"
      ></path>
      <path
        stroke="#C9D1E0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="6.096"
        d="M77.94 4h5.554c6.828 0 10.242 0 12.85 1.33a12.191 12.191 0 015.327 5.327C103 13.265 103 16.68 103 23.507v4.876"
      ></path>
      <path
        stroke="#347DEF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="6.096"
        d="M77.94 102.886h5.554c6.828 0 10.242 0 12.85-1.329a12.192 12.192 0 005.327-5.328C103 93.621 103 90.208 103 83.38v-4.876"
      ></path>
      <path
        stroke="#C9D1E0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="6.096"
        d="M77.94 102.886h5.554c6.828 0 10.242 0 12.85-1.329a12.192 12.192 0 005.327-5.328C103 93.621 103 90.208 103 83.38v-4.876"
      ></path>
      <path
        stroke="#347DEF"
        strokeLinecap="round"
        strokeWidth="7.45"
        d="M30.866 39.559v8.127"
      ></path>
      <path
        stroke="#C9D1E0"
        strokeLinecap="round"
        strokeWidth="7.45"
        d="M30.866 39.559v8.127"
      ></path>
      <path
        stroke="#347DEF"
        strokeLinecap="round"
        strokeWidth="7.45"
        d="M76.245 39.559v8.127"
      ></path>
      <path
        stroke="#C9D1E0"
        strokeLinecap="round"
        strokeWidth="7.45"
        d="M76.245 39.559v8.127"
      ></path>
      <path
        stroke="#347DEF"
        strokeLinecap="round"
        strokeWidth="5.08"
        d="M55.249 38.204v19.98c0 4.74-2.37 6.096-7.789 6.096"
      ></path>
      <path
        stroke="#C9D1E0"
        strokeLinecap="round"
        strokeWidth="5.08"
        d="M55.249 38.204v19.98c0 4.74-2.37 6.096-7.789 6.096"
      ></path>
      <path
        stroke="#347DEF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5.418"
        d="M36.624 77.148s5.418 6.096 16.932 6.096 16.256-6.096 16.256-6.096"
      ></path>
      <path
        stroke="#C9D1E0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5.418"
        d="M36.624 77.148s5.418 6.096 16.932 6.096 16.256-6.096 16.256-6.096"
      ></path>
    </svg>
  );
}

export default IconFaceID;
