import React from "react";

function IconCategories() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="36"
      fill="none"
      viewBox="0 0 37 36"
    >
      <g
        fill="#6AA7F8"
        clipPath="url(#clip0_359_1796)"
        filter="url(#filter0_i_359_1796)"
      >
        <path d="M4.252 2h12a2 2 0 010 4h-12a2 2 0 110-4zM4.127 9h18.125a2 2 0 010 4H4.127a2 2 0 110-4zM10.127 16h20.25a1.875 1.875 0 010 3.75h-20.25a1.875 1.875 0 110-3.75zM10.252 23h15a2 2 0 010 4h-15a2 2 0 110-4zM4.252 30h13a2 2 0 010 4h-13a2 2 0 110-4z"></path>
      </g>
      <defs>
        <filter
          id="filter0_i_359_1796"
          width="36"
          height="36"
          x="0.252"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_1796"></feBlend>
        </filter>
        <clipPath id="clip0_359_1796">
          <path
            fill="#fff"
            d="M0 0H36V36H0z"
            transform="translate(.252)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconCategories;
