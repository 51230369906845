import * as React from "react";

import { DoubleFeature, FeaturePanel, SymbolPanel } from "../content-elements";

import Grid from "../../../shared/grid";
import { H2 } from "../../../shared/headings";
import Parallax from "../../../shared/Parallax";
import Scale from "../../../shared/Scale";
import Section from "../../../shared/section";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

const Investments = () => {
  return (
    <Section dense>
      <Grid>
        <H2 style={{marginBottom: 0}}>Zoom out and get the big picture</H2>
      </Grid>
          <HeroContainer>
            <Scale>
              <Hero>
                <StaticImage src="./images/investments-desktop.png" alt="" width={1575} quality={100} placeholder="blurred"/>
              </Hero>
            </Scale>
          </HeroContainer>
      <Grid>
        <HeroText>
          <Parallax offset={100}>
            <CustomH2>Stocks<br/>Mutual Funds<br/>ETFs<br/>Bonds<br/>Real Estate<br/>Crypto</CustomH2>
            <Subhead>All your investments together in harmony</Subhead>
          </Parallax>
        </HeroText>
        <DoubleFeature style={{position: "relative"}}>
          <FeaturePanel 
            title="Real Estate" 
            description="Enter your property address and Copilot will track your home&rsquo;s estimated value"
          >
            <StaticImage alt="" src="./images/real-estate-illustration.png" width={340} quality={100} placeholder="none" />
          </FeaturePanel>
          <FeaturePanel title="Net-worth tracking" description="Keep tabs on your net worth with consolidated views of your accounts and investments">
            <StaticImage alt="" src="./images/net-worth-tracking-illustration.png" width={340} quality={100} placeholder="none" />
          </FeaturePanel>
        </DoubleFeature>
        <PerformanceRow>
          <SymbolPanel
            symbol="AAPL" 
            company="Apple Inc"
            change={1.91}
            path="M4 58L9.81098 2L15.622 15.0279L20.6028 21.5595L26.4138 29.9131L31.9481 27.4766H37.2056L43.8467 33.0189L48.2742 29.9131L54.0851 33.0189L59.6194 39.843L64.877 33.0189H71.2414L76.2222 27.8492"
          />
          <SymbolPanel
            symbol="MSFT" 
            company="Microsoft C..."
            change={1.64  }
            path="M4 54.6733L9.81098 58L15.622 51.6238L20.6028 41.9208L26.4138 35.2673L31.9481 33.3267L37.2056 26.6733H43.8467L48.2742 28.6139L54.0851 18.3564L59.6194 48.8515L64.877 28.6139L71.2414 16.6931L76.2222 2"
          />
          <SymbolPanel
            symbol="TSLA" 
            company="Tesla Inc"
            change={1.52}
            path="M4 29.544L9.81098 35.43L15.622 48.3011L20.6028 53.938L26.4138 50.2542L31.9481 39.5187L37.2056 39.8791L43.8467 37.183L48.2742 31.7418L54.0851 40.6666L59.6194 58L64.877 43.8743L73.2414 2L78.2222 3.52601"
          />
          <SymbolPanel
            symbol="VTI" 
            company="Vanguard T..."
            change={1.47}
            path="M4 57.4694L9.81098 58L15.622 53.0003L20.6028 38.7805L26.4138 39.1676L31.9481 36.3237L37.2056 26.5729H43.8467L48.2742 29.4169L54.0851 39.0051L59.6194 48.9375L64.877 19.2789L71.2414 13.395L76.2222 2"
          />
          <SymbolPanel
            symbol="SPY"
            company="SPDR S&P 5..."
            change={1.12}
            path="M4 54.6733L9.81098 52L15.622 57.6238L20.6028 41.9208L26.4138 39.2673L31.9481 37.3267L37.2056 26.6733H43.8467L48.2742 31.6139L54.0851 16.3565L59.6194 1.8515L64.877 22.6139L71.2414 16.6931L76.2222 19"
          />
          <PerformanceMask/>
        </PerformanceRow>
        <PerformanceLabel>Live performance estimates</PerformanceLabel>
      </Grid>
    </Section>
  )
}

const PerformanceRow = styled.div`
  margin-top: 24px;
  grid-column: span 12;
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(5, 130px);
  gap: 8px;
  ${props => props.theme.device.tablet} {
    grid-column: col-start 3 / span 9;
    grid-template-columns: repeat(5, 190px);
    gap: 16px;
  }
`
const PerformanceMask = styled.div`
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, rgba(1,9,18,0) 85%, rgba(1,9,18,1) 100%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  ${props => props.theme.device.tablet} {
    background-image: linear-gradient(to right, rgba(1,9,18,0) 75%, rgba(1,9,18,1) 90%);
  }
`
const PerformanceLabel = styled.div`
  grid-column: span 12;
  background: linear-gradient(90deg, #2BB61A 3.07%, #8EBDF9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: ${props => props.theme.fontSize.regular};
  font-weight: 600;
  width: max-content;
  margin: 0 auto;
  margin-bottom: 56px;
  ${props => props.theme.device.tablet} {
    grid-column: col-start 3 / span 9;
    margin: 0;
    margin-bottom: 40px;
  }
  &:before {
    display: inline-block;
    content: "";
    width: 12px;
    height: 12px;
    background-color: #30B725;
    border-radius: 6px;
    margin-right: 8px;
  }
`
const Hero = styled.div`
  width: calc(0.5 * 1575px);
  height: calc(0.5 * 1115px);
  margin: 0 auto;
  text-align: center;
  margin-top: -50px;
  ${props => props.theme.device.mobileLg} {
    width: calc(0.65 * 1575px);
    height: calc(0.65 * 1115px);
    margin-top: -70px;
    margin-left: -150px;
  }
  ${props => props.theme.device.tablet} {
    width: 1575px;
    height: 1115px;
    margin-top: -100px;
    margin-left: -50px;
  }
  ${props => props.theme.device.laptop} {
    margin-left: auto;
  }
`
const HeroContainer = styled.div`
  width: 100%;
  height: calc(0.5 * 1115px);
  overflow: hidden;
  ${props => props.theme.device.mobileLg} {
    height: calc(0.75 * 1115px);
  }
  ${props => props.theme.device.tablet} {
    height: 1115px;
  }
`
const HeroText = styled.div`
  grid-column: col-start / span 12;
  margin-top: -180px;
  margin-left: 16px;
  ${props => props.theme.device.mobileLg} {
    margin-top: -280px;
  }
  ${props => props.theme.device.tablet} {
    grid-column: col-start 7 / span 6;
    margin-top: -350px;
    margin-left: 16px;
  }
  margin-bottom: 40px;
`

const CustomH2 = styled(H2)`
  background: linear-gradient(180deg, #D2DAE4 3.07%, #8EBDF9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-align: left;
  font-weight: 800;
  line-height: 120%;
  letter-spacing: -0.02em;
  margin-bottom: 20px;
  position: relative;
  ${props => props.theme.device.tablet} {
    line-height: 100%;
    letter-spacing: -0.02em;
    margin-bottom: 40px;
  }
  &:before {
    content: "";
    display: block;
    background-color: ${props => props.theme.color.bg};
    ${props => props.theme.device.mobileLg} {
      filter: blur(30px);
      width: 100%;
    }
    height: 100%;
    margin: -40px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`
const Subhead = styled.p`
  grid-column: col-start 7 / span 6;
  max-width: 250px;
  text-align: left;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: ${props => props.theme.color.textLink};
`

export default Investments
