import { themeObject } from "../../../../../global-style";
import { ETradeIcon, RobinhoodIcon, WealthfrontIcon } from "./brokerage-logos";
import { LucasBudget } from "./budget-graphs";
import { LucasRecurring } from "./recurring-graphs";

const Lucas = {
  "integrations": {
    "amazon": [],
    "venmo": []
  },
  "rules": [{
    "rule": "Meriwether Prep",
    "category": { "name": "Tuition", "color": themeObject.color.categoryGreen, "emoji": "📚" }
  },
  {
    "rule": "Robinhood",
    "category": { "name": "Investments", "color": themeObject.color.categoryOrange, "emoji": "🤞" }
  },
  {
    "rule": "Home Depot",
    "category": { "name": "Improvement", "color": themeObject.color.categoryGreen, "emoji": "🛠" }
  }
  ],
  "budget": {
    "left": "5,941",
    "total": "5,658",
    "graph": LucasBudget,
    "budgeting_disabled": true,
  },
  "spending": {
    "status": "1.5",
    "over": true,
    "budgeting_disabled": true,
    "categories": [
      { "name": "Improvement", "color": themeObject.color.categoryGreen, "emoji": "🛠", "performance": 153 },
      { "name": "Kiddos", "color": themeObject.color.categoryLime, "emoji": "🚸", "performance": 67 },
      { "name": "Household", "color": themeObject.color.categoryPeach, "emoji": "🏠", "performance": 65 },
      { "name": "Food", "color": themeObject.color.categoryPurple, "emoji": "🍕", "performance": 53 },
      { "name": "Subscriptions", "color": themeObject.color.categoryTan, "emoji": "💳", "performance": 50 },
      { "name": "College Fund", "color": themeObject.color.categoryAqua, "emoji": "🏫", "performance": 50 },
      { "name": "Tuition", "color": themeObject.color.categoryGreen, "emoji": "📚", "performance": 50 },
      { "name": "Investments", "color": themeObject.color.categoryOrange, "emoji": "📊", "performance": 50 },
      { "name": "Transportation", "color": themeObject.color.categoryPurple, "emoji": "🚐", "performance": 43 },
      { "name": "Exercise", "color": themeObject.color.categoryGreen, "emoji": "🏋🏿", "performance": 38 },
      { "name": "Entertainment", "color": themeObject.color.categoryLime, "emoji": "🎢", "performance": 22 }
    ]
  },
  "investments": [
    { "name": "E*Trade", "balance": "18,119.88", "performance": 1.74, icon: ETradeIcon },
    { "name": "Robinhood", "balance": "11,925.21", "performance": 2.07, icon: RobinhoodIcon },
    { "name": "Wealthfront", "balance": "6,407.92", "performance": 1.22, icon: WealthfrontIcon }
  ],
  "recurrings": {
    "left": 65,
    "total": 2312,
    "graph": LucasRecurring,
    "schedule": [
      { "name": "Hello Fresh", "emoji": "🍲", "balance": "410.50", "date": "1st", "paid": true },
      { "name": "Rent", "emoji": "💸", "balance": "1,900", "date": "15th", "paid": true },
      { "name": "Disney Plus", "emoji": "📺", "balance": "9.99", "date": "1st", "paid": true },
      { "name": "LA Fitness", "emoji": "💪🏾", "balance": "29.99", "date": "21st", "paid": false },
    ]
  }
}

export default Lucas;