import React from "react"
import { 
    Tabs, 
    TabList, 
    // TabPanels, 
    // TabPanel, 
    useTabsContext, 
    Tab 
} from "@reach/tabs"
import "@reach/tabs/styles.css"
import styled from "styled-components"
import { elementScrollIntoViewPolyfill } from "seamless-scroll-polyfill";
import { detect } from 'detect-browser';
import Panels from "./panels";
import SamAndBo from "./personas/sam-and-bo";
import Lucas from "./personas/lucas";
import Emily from "./personas/emily";
import Mateo from "./personas/mateo";
import { SBIcon, LucasIcon, EmilyIcon, MateoIcon } from "./app-icons";
import useStore from "./store";

// polyfill for smooth scrolling of feature tabs in safari
// make sure we're in a browser and not server rendering, since polyfill code requires access to the document object
// also, we're forcing the polyfill on ios 
if (typeof window !== 'undefined') {
    const browser = detect();
    elementScrollIntoViewPolyfill({
        forcePolyfill: browser?.name === 'ios'
    });
}

const FeatureTab = React.forwardRef((props, ref) => {
    const { selectedIndex, focusedIndex } = useTabsContext();
    return (
        <StyledFeatureTab
            selectedindex={selectedIndex}
            focusedindex={focusedIndex}
            index={props.index}
            ref={ref}
            {...props}
        />
    )
});

function CustomizationPanel() {
    const [tabIndex, setTabIndex] = React.useState(0);
    const tabRefs = React.useRef([]);

    const currentPersona = useStore((state) => state.persona);
    const changePersona = useStore((state) => state.setPersona);

    const tabs = [
        { customer: "Sam + Bo", aim: " are managing their family's finances", icon: SBIcon},
        { customer: "Lucas", aim: " is building up his savings", icon: LucasIcon},
        { customer: "Emily", aim: " is keeping her first budget on track", icon: EmilyIcon},
        { customer: "Mateo", aim: " is tracking shared expenses", icon: MateoIcon}
    ];

    const handleTabsChange = (index) => {
        setTabIndex(index);
        switch (index) {
            case 0: 
                changePersona(SamAndBo);
                break;
            case 1:
                changePersona(Lucas);
                break;
            case 2:
                changePersona(Emily);
                break;
            case 3:
                changePersona(Mateo);
                break;
            default: 
                changePersona(SamAndBo);
                break;
        }

        // scroll selected tab towards horizontal center of screen
        if(typeof window !== 'undefined') {
            tabRefs.current[index].scrollIntoView({
                behavior: "smooth",
                inline: "center",
                block: "nearest",
                // set a custom duration used by the polyfill
                duration: 150
            });
        }
    };

    return (
        <>
            <Tabs index={tabIndex} onChange={handleTabsChange}>
                <StyledTabList>
                        {tabs.map((tab, index) => {
                            const Icon = tab.icon;
                            return (
                            <FeatureTab 
                                key={index} 
                                custom={index} 
                                index={index}
                                ref={el => tabRefs.current[index] = el}
                            >
                                <Icon/>
                                <PersonaInfo>{tab.customer}<span className="aim">{tab.aim}</span></PersonaInfo>
                            </FeatureTab>
                            )
                        }
                        )}
                    <Spacer/>
                </StyledTabList>
            </Tabs>
            <Panels/>
        </>
    )
}

const PersonaInfo = styled.div`
    max-width: 125px;
    font-size: 0.875rem;
    line-height: 1.3;
    ${props => props.theme.device.tablet} {
        max-width: 106px;
    }
`
const StyledFeatureTab = styled(Tab)`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    padding: 12px 12px;
    font-size: ${props => props.theme.fontSize.small};
    font-weight: 600;
    color: ${({ theme }) => theme.color.textHighContrast};
    letter-spacing: -0.01em;
    line-height: 20px;
    text-align: left;
    background-color: transparent;
    transition: color 0.15s ease, background-color 0.15s ease;
    & svg {
        box-shadow: 0 2px 6px rgba(1,9,18,0.3);
        border-radius: 25%;
        // height: min-content;
        margin: 0 auto;
        min-width: 40px;
    }
    &:first-child {
        margin-left: ${({ theme }) => theme.spacing[5]};
    }
    &:last-child {
        /* using a spacer because this isn't working */
        // margin-right: ${({ theme }) => theme.spacing[5]};
    }
    & span.aim {
        color: #5D7696;
    }
    &[data-selected] {
        color: ${({ theme }) => theme.color.bg};
        border-bottom-color: transparent;
        background-color: ${({ theme }) => theme.color.textLink};
        & span.aim {
            color: #11315F;
        }
    }
    &:hover {
        background-color: rgba(7,28,55,0.75);
    }
    &:hover[data-selected] {
        background-color: ${({ theme }) => theme.color.textLink};
    }
    &:focus {
        outline: none;
    }
    &:focus-visible {
        box-shadow: inset 0 0 0 2pt ${props => props.theme.color.textPrimary};
    }

    ${props => props.theme.device.mobileMd} {
        font-size: ${props => props.theme.fontSize.small};
        padding: 12px 12px;
    }

    ${props => props.theme.device.tablet} {
        border-radius: 26px;
        flex-direction: column;
        align-items: flex-start;
        align-items: center;
        text-align: left;
        & svg {
            margin-left: 0;
        }
        font-size: ${props => props.theme.fontSize.regular};
        padding: 16px;
    }

    ${props => props.theme.device.laptop} {
        flex-direction: row;
        text-align: left;
        font-size: ${props => props.theme.fontSize.regular2};
        padding: 19px 20px;
    }
`

// const StyledTabPanel = styled(TabPanel)`
//     &:focus {
//         outline: none;
//     }
//     &:focus-visible {
//         box-shadow: inset 0 0 0 2pt ${props => props.theme.color.uiCardGround};
//     }
// `

// const StyledTabPanels = styled(TabPanels)`
//     // margin-top: 3rem;
// `

const StyledTabList = styled(TabList)`
    background-color: transparent;
    width: 100%;
    padding-bottom: ${({ theme }) => theme.spacing[3]};
    margin-bottom: 0;
    // white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    gap: 16px;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
    }

    ${({ theme }) => theme.device.tablet} {
        justify-content: center;
        margin: 0;
        margin-bottom: ${({ theme }) => theme.spacing[6]};
    }
`

const Spacer = styled.div`
    background-color: transparent;
    min-width: ${({ theme }) => theme.spacing[5]};
    ${({ theme }) => theme.device.tablet} {
        display: none;
    }
`

export default CustomizationPanel