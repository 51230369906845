import { AppleReview, TwitterReview } from "./review";

import Grid from "../../shared/grid";
import { H2 } from "../../shared/headings";
import { OutboundLink } from "../../shared/Href";
import React from "react";
import Section from "../../shared/section";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

const Reviews = () => {
    return (
        <Section id="reviews">
            <Grid>
                <H2>The reviews speak for themselves</H2>
                <Header>
                    <AppStoreRating href="https://apps.apple.com/us/app/copilot-smart-budgets-bills/id1447330651#see-all/reviews">
                        <Rating aria-label="4.8 out of 5 stars">4.8<OutOf/>5</Rating>
                        <Stars/>
                        <RatingMeta>
                            <ReviewCount>8,500+ reviews</ReviewCount>
                        </RatingMeta>
                    </AppStoreRating>
                    <FirstAward>
                      <AwardLink target="_blank" rel="noopener noreferrer" href="https://www.nytimes.com/2023/11/11/your-money/mint-budgeting-app-alternatives.html">
                          <StaticImage
                              layout="constrained"
                              width={80}
                              src="./nyt.png"
                              alt="New York Times"
                              placeholder="blurred"
                              quality={100}
                              />
                      </AwardLink>
                    </FirstAward>
                    <div>
                        <AwardLink target="_blank" rel="noopener noreferrer" href="https://www.theverge.com/2023/11/12/23955681/best-apps-online-budget-money-mint-ynab-copilot-steam-deck-humane-ai">
                            <StaticImage
                                layout="constrained"
                                width={80}
                                src="./theverge.png"
                                alt="The Verge"
                                placeholder="blurred"
                                quality={100}
                                />
                        </AwardLink>
                    </div>
                    <LastAward>
                        <AwardLink target="_blank" rel="noopener noreferrer" href="https://techcrunch.com/2024/03/21/budgeting-app-copilot-mint-6m-series-a/">
                            <StaticImage
                                layout="constrained"
                                width={80}
                                src="./techcrunch.png"
                                alt="TechCrunch"
                                placeholder="blurred"
                                quality={100}
                                />
                        </AwardLink>
                    </LastAward>
                    <div>
                        <AwardLink target="_blank" rel="noopener noreferrer" href="https://winners.webbyawards.com/2021/apps-and-software/mobile-ott-app-features/best-user-experience/176137/copilot">
                            <StaticImage
                                layout="constrained"
                                width={448} 
                                src="./webby-award-nominations.png"
                                alt="Webby Award nominations"
                                placeholder="blurred"
                                quality={100}
                                />
                        </AwardLink>
                    </div>
                </Header>
            </Grid>
            <Wrapper>
                <ReviewGrid>
                    <ReviewColumn>
                        <TwitterReview
                            author="Luca Defreitas-Hansen"
                            username="@lucadefreitash"
                            verified={false}
                            message={<React.Fragment>Downloaded <TwLink>@copilotmoney</TwLink> yesterday and already love it. Have multiple cards connected and it breaks everything down and so easy to use</React.Fragment>}
                        >
                            <StaticImage
                                src="./twitter-avatars/lucadefreitash.jpg"
                                width={35.2}
                                quality={80}
                                placeholder="none"
                                alt=""
                            />
                        </TwitterReview>
                        <TwitterReview
                            author="TylerByte"
                            username="@tylerbytes"
                            verified={false}
                            message={<React.Fragment><TwLink>@copilotmoney</TwLink> is the best money app I&rsquo;ve ever used!!</React.Fragment>}
                        >
                            <StaticImage
                                src="./twitter-avatars/tylerbytes.jpeg"
                                width={35.2}
                                quality={80}
                                placeholder="none"
                                alt=""
                            />
                        </TwitterReview>
                        <TwitterReview
                            avatar="https://pbs.twimg.com/profile_images/1260329587004727296/AGBpyYrO_400x400.jpg"
                            author="Sunpreet"
                            username="@sooonpreet"
                            verified={false}
                            message={<React.Fragment><TwLink>@copilotmoney</TwLink> is awesome, clean design and easy to use</React.Fragment>}
                        >
                            <StaticImage
                                src="./twitter-avatars/sooonpreet.jpeg"
                                width={35.2}
                                quality={80}
                                placeholder="none"
                                alt=""
                            />
                        </TwitterReview>
                        <TwitterReview
                            avatar="https://pbs.twimg.com/profile_images/1260329587004727296/AGBpyYrO_400x400.jpg"
                            author="enelay"
                            username="@enelayy"
                            verified={false}
                            message={<React.Fragment>Early <TwLink>@copilotmoney</TwLink> user, few apps have become homescreen fixtures so quickly. Go get it!</React.Fragment>}
                        >
                            <StaticImage
                                src="./twitter-avatars/enelayy.jpeg"
                                width={35.2}
                                quality={80}
                                placeholder="none"
                                alt=""
                            />
                        </TwitterReview>
                    </ReviewColumn>
                    <ReviewColumn>
                        <AppleReview
                            rating={5}
                            attributionString="sietedos, 11/02/2023"
                            title="So good and waaay better than mint!"
                            message={<React.Fragment>I was on mint for years, but I&rsquo;m so happy I switched. Managing my finances is now fun thanks to Copilot. Amazing app and incredible support team that actually listen to their customers!</React.Fragment>}
                        />
                        <AppleReview
                            rating={5}
                            attributionString="David Thaten, 11/10/2023"
                            title="The Best Budgeting App!!"
                            message={<React.Fragment>Copilot has been a magical experience so far.
                              They&rsquo;ve innovated the experience of budgeting - it helps keep you informed, but the right things are automatic and effortless. You can choose how manual you&rsquo;d like it to be - or how much you&rsquo;d like the app to do the work for you.
                              They&rsquo;ve solved most of the gaps from mint - and it&rsquo;s easy to get a budget set up.
                              PFM has been over complicated for too long but Copilot has done a great job of making it easy.</React.Fragment>}
                        />
                    </ReviewColumn>
                    <ReviewColumn>
                        <TwitterReview
                            avatar="https://pbs.twimg.com/profile_images/1260329587004727296/AGBpyYrO_400x400.jpg"
                            author="Raven Hernandez"
                            username="@RavenSHernandez"
                            verified={true}
                            message={<React.Fragment>I use <TwLink>@copilotmoney</TwLink> and actually enjoy it. I&rsquo;ve gotten a few friends hooked too.</React.Fragment>}
                        >
                            <StaticImage
                                src="./twitter-avatars/RavenSHernandez.jpeg"
                                width={35.2}
                                quality={80}
                                placeholder="none"
                                alt=""
                            />
                        </TwitterReview>
                        <TwitterReview
                            avatar="https://pbs.twimg.com/profile_images/1260329587004727296/AGBpyYrO_400x400.jpg"
                            author="John D Saunders"
                            username="@johndsaunders"
                            verified={false}
                            message={<React.Fragment>Been using <TwLink>@mint</TwLink> for close to a decade and switched to <TwLink>@copilotmoney</TwLink> last week and DAMN.<br/><br/>
                              This IS the move.</React.Fragment>}
                        >
                            <StaticImage
                                src="./twitter-avatars/johndsaunders.jpeg"
                                width={35.2}
                                quality={80}
                                placeholder="none"
                                alt=""
                            />
                        </TwitterReview>
                        <TwitterReview
                            avatar="https://pbs.twimg.com/profile_images/1260329587004727296/AGBpyYrO_400x400.jpg"
                            author="Ehsan Mafi"
                            username="@essonjon"
                            verified={false}
                            message={<React.Fragment>I use <TwLink>@copilotmoney</TwLink> and I love it!</React.Fragment>}
                        >
                            <StaticImage
                                src="./twitter-avatars/essonjon.jpeg"
                                width={35.2}
                                quality={80}
                                placeholder="none"
                                alt=""
                            />
                        </TwitterReview>
                        <TwitterReview
                            avatar="https://pbs.twimg.com/profile_images/1260329587004727296/AGBpyYrO_400x400.jpg"
                            author="Majd Taby"
                            username="@jtaby"
                            verified={false}
                            message={<React.Fragment>Shoutout to <TwLink>@copilotmoney</TwLink> for being so so good. Finally got an app with a dashboard showing me all my accounts in one place.</React.Fragment>}
                        >
                            <StaticImage
                                src="./twitter-avatars/jtaby.jpeg"
                                width={35.2}
                                quality={80}
                                placeholder="none"
                                alt=""
                            />
                        </TwitterReview>
                    </ReviewColumn>
                    <ReviewColumn>
                        <AppleReview
                            rating={5}
                            attributionString="legochris100, 11/12/2023"
                            title="Amazing Design, Great App to Switch To"
                            message={<React.Fragment>I&rsquo;ve used Mint and Quicken in the past, been somewhat of a power user with Quicken. I love how friendly the iPhone and Mac apps are to use, and how much effort has been spent to get the UX to feel native and clean. They&rsquo;re also really quick to roll out new features, which has been awesome to see the app getting constantly better.
                              If you&rsquo;re looking for a way to track your spending and budgeting, definitely give this app a try!</React.Fragment>}
                        />
                        <AppleReview
                            rating={5}
                            attributionString="Filmstudio11, 11/10/2023"
                            title="Totally worth the subscription!"
                            message={<React.Fragment>This app is amazing. I never thought I would be spending money on a budgeting app but this one is worth it! This is a 5/5 app no questions asked! I recommend this to everyone who cares about seeing the big picture with their personal finances.
                            </React.Fragment>}
                        />
                    </ReviewColumn>
                </ReviewGrid>
            </Wrapper>
        </Section>
    )
}

const TwLink = styled.span`
    color: #50BCFF;
`
const ReviewGrid = styled.div`
    display: grid;
    font-size: inherit;
    grid-template-columns: repeat(4, 1fr);
    margin: 0 auto;
    max-width: 1600px;
    min-width: 1220px;
    gap: clamp(0.625rem, 0.1579rem + 0.8421vw, 1.25rem); // fluidly resize between 1220px and 1600px.
    padding: 0 12px;
    font-size: clamp(0.8rem, 0.1579rem + 0.8421vw, 1rem); // fluidly resize between 1220px and 1600px.
    ${props => props.theme.device.tablet} {
        // gap: 20px;
        padding: 0 40px;
    }
    ${props => props.theme.device.laptopLg} {
        padding: 0 48px;
    }

`
const ReviewColumn = styled.div`
    grid-column: span 1;
    display: flex;
    flex-direction: column;
    gap: clamp(0.625rem, 0.1579rem + 0.8421vw, 1.25rem); // fluidly resize between 1220px and 1600px.
`
const Wrapper = styled.div`
    display: block;
    margin: 0 auto;
    height: auto;
    width: 100%;
    overflow-x: scroll;
    margin-top: 24px;
    padding-bottom: 12px;
    ::-webkit-scrollbar {
        height: 8px;
        width: 8px;
        background: #010912;
    }
    ::-webkit-scrollbar-thumb {
        background: ${props => props.theme.color.textSecondary};
        // background: #6B6B6B;
        -webkit-border-radius: 1ex;
        -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    }
    ::-webkit-scrollbar-corner {
        background: #010912;
    }
    // mozilla
    scrollbar-color: ${props => props.theme.color.textSecondary} #010912;
    scrollbar-width: thin;
`



const Header = styled.div`
    grid-column: col-start / span 12;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 24px;
    column-gap: 24px;
    ${props => props.theme.device.laptop} {
        justify-content: space-between;
        flex-wrap: nowrap;
    }
`
const MoreLinkContent = styled.div`
    color: ${props => props.theme.color.textLink};
    display: inline-block;
    position: relative;
    margin: 1rem auto;
    padding-bottom: 0.1em;
    text-align: center;
    font-weight: 600;
    font-size: ${props => props.theme.fontSize.medium};
    overflow: hidden;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.1em;
        background-color: ${props => props.theme.color.textLink};
        opacity: 1;
        transform: translate3d(-101%, 0, 0);
        transition: opacity 300ms, transform 300ms;
    }
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.1em;
        background-color: ${props => props.theme.color.textLink};
        opacity: 0.3;
        transform: translate3d(0, 0, 0);
    }
    & svg {
        color: ${props => props.theme.color.textLink};
        width: 1.5em;
        margin: 0.5em auto;
        display: block;
        opacity: 0.3;
        transition: color 300ms, opacity 300ms;
    }
`

const MoreLink = styled.a`
    grid-column: col-start / span 12;
    place-self: center;
    max-width: 300px;
    text-align: center;
    margin-top: 1rem;
    &:hover, &:focus {
        & ${MoreLinkContent} {
            &::after {
                transform: translate3d(0, 0, 0);
            }
            & svg {
                opacity: 1;
                color: ${props => props.theme.color.textLink};
            }
        }
    }
`

const ReviewCount = styled.div`
    font-size: 1.25rem;
    position: relative;
    padding-bottom: 0.1em;
    display: inline-block;
    margin-bottom: 0.5rem;
    overflow: hidden;
    color: ${props => props.theme.color.textLink};
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.1em;
        background-color: ${props => props.theme.color.textLink};
        opacity: 1;
        transform: translate3d(-101%, 0, 0);
        transition: opacity 300ms, transform 300ms;
    }
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.1em;
        background-color: ${props => props.theme.color.textLink};
        opacity: 0.3;
        transform: translate3d(0, 0, 0);
    }
`

const AppStoreRating = styled(OutboundLink)`
    margin: 0 auto;
    text-align: center;
    width: 100%;
    color: ${props => props.theme.color.textPrimary};
    ${props => props.theme.device.laptop} {
        width: unset;
        min-width: 240px;
        margin: 0;
        text-align: left;
    }
    &:hover, &:focus {
        & ${ReviewCount} {
            &::after {
                transform: translate3d(0, 0, 0);
            }
        }
    }
`

const Rating = styled.div`
    display: flex;
    align-items: center;
    font-size: 2.625rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    justify-content: center;
    ${props => props.theme.device.laptop} {
        justify-content: flex-start;
    }
`
const OutOf = styled.div`
    display: inline-block;
    height: 2.625rem;
    width: 0.25rem;
    border-radius: 2px;
    transform: rotate(20deg);
    margin: 0 0.75rem;
    background-color: ${props => props.theme.color.textSecondary};
`
const RatingMeta = styled.div`
    & > div {
        display: inline-block;
        padding-top: 0.5rem;
    }
`

const FirstAward = styled.div`
    ${props => props.theme.device.laptop} {
        padding-left: 8%;
    }
    ${props => props.theme.device.laptopLg} {
        padding-left: 11%;
    }
`
const LastAward = styled.div`
    ${props => props.theme.device.tablet} {
        padding-right: 10%;
    }
    ${props => props.theme.device.laptop} {
        padding-right: 10%;
    }
    ${props => props.theme.device.laptopLg} {
      padding-right: 13%;
    }
`
const AwardLink = styled(OutboundLink)`
    opacity: 0.75;
    transition: opacity 300ms;
    &:hover {
        opacity: 1.0;
    }
`

const Stars = (props) => {
    const fill = props.fill || "#FED774";
    const bg = props.bg || "#5D7696";
    return (
        <svg width="222" height="40" viewBox="0 0 222 40" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path fill={bg} opacity="0.6" d="M201.297 0L206.185 15.0414H222L209.205 24.3375L214.092 39.3789L201.297 30.0828L188.502 39.3789L193.39 24.3375L180.595 15.0414H196.41L201.297 0Z"/>
            <path d="M25.59 15.0414L20.7027 0L15.8155 15.0414H0L12.795 24.3375L7.90774 39.3789L20.7027 30.0828L33.4977 39.3789L28.6105 24.3375L41.4055 15.0414H25.59Z"/>
            <path d="M70.7387 15.0414L65.8515 0L60.9642 15.0414H45.1487L57.9437 24.3375L53.0565 39.3789L65.8515 30.0828L78.6464 39.3789L73.7592 24.3375L86.5542 15.0414H70.7387Z"/>
            <path d="M111 0L115.887 15.0414H131.703L118.908 24.3375L123.795 39.3789L111 30.0828L98.2052 39.3789L103.092 24.3375L90.2974 15.0414H106.113L111 0Z"/>
            <path d="M161.036 15.0414L156.149 0L151.262 15.0414H135.446L148.241 24.3375L143.354 39.3789L156.149 30.0828L168.944 39.3789L164.057 24.3375L176.852 15.0414H161.036Z"/>
            <path d="M201.298 0L206.185 15.0414H209.963L205.944 33.4589L201.298 30.0828L188.503 39.3789L193.39 24.3375L180.595 15.0414H196.41L201.298 0Z"/>
        </svg>
    )
}

export default Reviews