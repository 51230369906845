import React, { 
    useState, 
    useRef, 
    useLayoutEffect 
} from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { 
    motion, 
    useScroll, 
    useTransform, 
    // useSpring 
} from "framer-motion";


const IMG_WIDTH = 279;
const IMG_HEIGHT = 569;

export const LightAndDarkMode = (props) => {

    const [elementTop, setElementTop] = useState(0)
    const [clientHeight, setClientHeight] = useState(0)
    const ref = useRef(null)
  
    const { scrollY } = useScroll()
  
    const initial = elementTop - clientHeight + 250
    const final = elementTop + IMG_HEIGHT - 250
  
    const yRange = useTransform(scrollY, [initial, final], [0, IMG_HEIGHT], {clamp: true})
    // const height = useSpring(yRange, { stiffness: 400, damping: 90 })
  
    useLayoutEffect(() => {
      const element = ref.current
      const onResize = () => {
        setElementTop(element.getBoundingClientRect().top + window.scrollY || window.pageYOffset)
        setClientHeight(window.innerHeight)
      }
      onResize()
      window.addEventListener('resize', onResize)
      return () => window.removeEventListener('resize', onResize)
    }, [ref])
  
    
    return (
            <ColorModeImages>
                    <ColorModeImage>
                        <StaticImage
                            layout="constrained"
                            width={IMG_WIDTH} 
                            src="./images/color-mode-light.png"
                            alt=""
                            placeholder="none"
                        />
                    </ColorModeImage>
                    <Mask
                        style={{height: yRange}}
                        ref={ref}
                    >
                        <ColorModeImage>
                            <StaticImage
                                layout="constrained"
                                loading="eager"
                                width={IMG_WIDTH} 
                                src="./images/color-mode-dark.png"
                                alt=""
                                placeholder="none"
                            />
                        </ColorModeImage>
                    </Mask>
            </ColorModeImages>
    )
}

const Mask = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: ${IMG_HEIGHT}px;
    width: 100%;
    overflow: hidden;
`
const ColorModeImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
`
const ColorModeImages = styled.div`
    margin: 0 auto;
    // margin-top: 3.3rem;
    text-align: center;
    position: relative;
    width: 279px;
`