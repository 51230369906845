import React from "react";

function IconReviews() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill="#3D89F7" clipPath="url(#clip0_433_3449)">
        <path d="M9.334 22.667H8a1.333 1.333 0 01-1.333-1.334V8.667h4v12.666c0 .737-.597 1.334-1.333 1.334zM8 3.333h1.334c.736 0 1.333.597 1.333 1.334v4h-4v-4c0-.737.597-1.334 1.333-1.334zM18.666 9.333H20c.736 0 1.333.597 1.333 1.334v2h-4v-2c0-.737.597-1.334 1.333-1.334zM17.423 3.333h3.82c.566 0 .874.66.513 1.094l-1.91 2.292a.667.667 0 01-1.025 0l-1.91-2.292a.667.667 0 01.512-1.094z"></path>
        <path d="M20.333 22.667h-2a1 1 0 01-1-1V11.333h4v10.334a1 1 0 01-1 1zM4 22.667H2.666a1.333 1.333 0 01-1.333-1.334V10c0-.736.597-1.333 1.333-1.333H4c.736 0 1.333.597 1.333 1.333v11.333c0 .737-.597 1.334-1.333 1.334zM14.667 22.667h-1.334A1.333 1.333 0 0112 21.333v-8.666c0-.737.597-1.334 1.333-1.334h1.334c.736 0 1.333.597 1.333 1.334v8.666c0 .737-.597 1.334-1.333 1.334z"></path>
      </g>
      <defs>
        <clipPath id="clip0_433_3449">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconReviews;
