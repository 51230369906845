import * as React from "react";
import CustomizationPanel from "./customization-panel";
import { H2 } from "../../../shared/headings";
import Grid from "../../../shared/grid";
import Section from "../../../shared/section";

const Customization = () => {
  return (
    <Section dense>
      <Grid>
        <H2>Endless possibilities to make it your own</H2>
      </Grid>
      <CustomizationPanel />
    </Section>
  )
}

export default Customization
