import * as React from "react";
import { H2, H5 } from "../../../shared/headings";
import Section from "../../../shared/section";
import Grid from "../../../shared/grid";
import styled from "styled-components";
import { FeatureCallout, StyledFeatureCallout, FeaturePanel } from "../content-elements";
import { StaticImage } from "gatsby-plugin-image";
import IconSplitRecurring from "./icons/IconSplitRecurring";
import IconMarkRecurring from "./icons/IconMarkRecurring";
import IconPriceChanges from "./icons/IconPriceChanges";
import IconPauseRecurring from "./icons/IconPauseRecurring";
import IconFrequency from "./icons/IconFrequency";
import useMediaQuery from "../../../shared/hooks/use-media-query";

const lowerIconSection = [
  { 
    Icon: IconPriceChanges, 
    title: "Catch price changes", 
    description: "Recurrings help you spot price hikes sooner" 
  },
  { 
    Icon: IconPauseRecurring, 
    title: "Pause recurrings", 
    description: "Your subscription will be there when you’re ready" 
  },
  { 
    Icon: IconFrequency, 
    title: "Set a frequency", 
    description: "Tune recurrings to the frequency you want" 
  },
]

const rightIconSection = [
  { 
    Icon: IconSplitRecurring, 
    title: "Split recurrings", 
    description: "Easily reconcile regularly shared expenses" 
  },
  { 
    Icon: IconMarkRecurring, 
    title: "Mark as recurring", 
    description: "Add new recurring transactions to Copilot" 
  },
]

const Recurrings = () => {
  const isTabletWidth = useMediaQuery(`(min-width: 768px)`);
  const isLaptopLgWidth = useMediaQuery(`(min-width: 1440px)`);
  return (
    <Section>
        <Grid>
          <H2>Manage recurring expenses</H2>
          { isTabletWidth ? (
            <Container>
              <HeroPanel>
                <Text>
                  <H5>See your bills at a glance</H5>
                  <p>Copilot detects your recurring transactions and organizes them into a monthly view</p>
                </Text>
                <StaticImage alt="" style={{ marginRight: -30 }} src="./images/recurring-payments-illustration.png" width={360} placeholder="none" />
              </HeroPanel>
              <RightSecondaryFeatures>
                { isLaptopLgWidth && rightIconSection.map((feature, index) => {
                  return <FeatureCallout 
                            key={index} 
                            Icon={feature.Icon} 
                            title={feature.title} 
                            description={feature.description} />
                }) }
              </RightSecondaryFeatures>
            </Container>
          ) : (
            <MobilePanel>
              <FeaturePanel title="See your bills at a glance" description="Copilot detects your recurring transactions and organizes them into a monthly view.">
                <StaticImage alt="" src="./images/recurring-payments-illustration.png" width={310} placeholder="none" />
              </FeaturePanel>
            </MobilePanel>
          )}
          <SecondaryFeatures>
            { !isLaptopLgWidth && rightIconSection.map((feature, index) => {
                  return <FeatureCallout 
                            key={index} 
                            Icon={feature.Icon} 
                            title={feature.title} 
                            description={feature.description} />
            }) }
            { lowerIconSection.map((feature, index) => {
              return <FeatureCallout 
                        key={index} 
                        Icon={feature.Icon} 
                        title={feature.title} 
                        description={feature.description} />
            }) }
          </SecondaryFeatures>
        </Grid>
    </Section>
  )
}

const Text = styled.div`
  padding: 20px;
  ${props => props.theme.device.tablet} {
    padding: 24px;
  }
  ${props => props.theme.device.laptopLg} {
    padding: 50px;
  }
`
const Container = styled.div`
  grid-column: col-start 3 / span 8;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
`
const HeroPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-column: span 3;
  overflow: visible;
  & h5 {
    max-width: 235px;
    text-align: center;
    margin: 0 auto;
  }
  & p {
    font-weight: 600;
    color: ${props => props.theme.color.textSecondary};
    max-width: 235px;
    text-align: center;
    margin: 0 auto;
  }
  ${props => props.theme.device.tablet} {
    border-radius: 10px;
    background: radial-gradient(94.91% 94.91% at 50% 50%,rgba(1, 69, 137, 0.22) 0%, rgba(0, 0, 0, 0) 100%), rgba(5,24,48,0.2);
    box-shadow: 0px -40px 76px -40px rgba(38, 91, 181, 0.1), 0px 4px 76px -160px rgba(106, 167, 248, 0.3);
    border: 1px solid rgba(48,137,255,0.1);
    padding-top: 24px;
    padding-bottom: 24px;
    margin-left: 0px;
    margin-right: 0px;
    & h5 {
      text-align: left;
      margin: 0;
    }
    & p {
        text-align: left;
        margin: 0;
    }
  ${props => props.theme.device.laptop} {
    margin-left: auto;
    margin-right: auto;
  }
  ${props => props.theme.device.laptopLg} {
    grid-column: span 2;
    margin-left: -40px;
    margin-right: 30px;
    padding-top: 56px;
    padding-bottom: 56px;
  }
}
`
const MobilePanel = styled.div`
  display: grid;
  margin: -12px 0 16px 0;
  grid-template-columns: 1fr;
  grid-column: span 12;
  gap: 24px;
  ${props => props.theme.device.tablet} {
    display: none;
  }
`

const SecondaryFeatures = styled.div`
  grid-column: span 12;
  justify-content: flex-start;
  &  ${StyledFeatureCallout} {
    ${props => props.theme.device.tablet} {
      text-align: center;
      width: auto;
    }
    ${props => props.theme.device.laptop} {
      text-align: center;
      width: 31%;
    }
    ${props => props.theme.device.laptopLg} {
      text-align: left;
      width: 31%;
    }
  }
  ${props => props.theme.device.tablet} {
    grid-column: col-start 3 / span 8;
  }
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 40px;
  gap: 8px;
`

// const RightSecondaryFeatures = styled(SecondaryFeatures)`
const RightSecondaryFeatures = styled.div`
  grid-column: span 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0;
  &  ${StyledFeatureCallout} {
    width: 100%;
    margin-bottom: 0;
  }
`


export default Recurrings
