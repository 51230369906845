import React from "react";
import { FeaturePanel } from "../content-elements";
import { StaticImage } from "gatsby-plugin-image";

const DailySnapshots = () => {
    return (
        <FeaturePanel 
            description="Review your recent spending, check on that refund, and see what bills are coming up"
            title="Daily snapshots"
        >
            <StaticImage 
                alt=""
                src="./images/daily-snapshots-illustration.png" 
                width={340} 
                quality={100} 
                placeholder="none" 
            />
        </FeaturePanel>
    )
}

export default DailySnapshots
