import React from "react";

function IconChanges() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill="#A2AB35" clipPath="url(#clip0_433_3492)">
        <path d="M21.106 22.505H2.82v-1.714a2.285 2.285 0 012.286-2.286H18.82a2.285 2.285 0 012.286 2.286v1.714zM11.964 4.22a.762.762 0 01-.762-.762V1.934a.762.762 0 111.524 0v1.524c0 .42-.34.762-.762.762zM6.037 6.674A.76.76 0 015.5 6.45L4.42 5.373A.762.762 0 115.5 4.296l1.077 1.077a.762.762 0 01-.539 1.3zM17.89 6.674a.762.762 0 01-.539-1.3l1.077-1.078a.762.762 0 111.078 1.077l-1.078 1.078a.759.759 0 01-.538.223zM3.583 12.6H2.059a.762.762 0 110-1.523h1.524a.762.762 0 110 1.524zM21.869 12.6h-1.524a.762.762 0 110-1.523h1.524a.762.762 0 110 1.524zM18.001 17.505v-5.622c0-3.333-2.692-6.044-6-6.044s-6 2.711-6 6.044v5.622h12zm-5.974-8.738a.75.75 0 01.913-.542 3.768 3.768 0 012.693 2.713.756.756 0 01-.727.945.75.75 0 01-.725-.566 2.262 2.262 0 00-1.616-1.63.755.755 0 01-.538-.92z"></path>
      </g>
      <defs>
        <clipPath id="clip0_433_3492">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconChanges;
