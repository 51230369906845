import React from "react";
import { FeaturePanel } from "../content-elements";
import { StaticImage } from "gatsby-plugin-image";

const SmartCategorization = () => {
    return (
        <FeaturePanel 
            description="Our data-driven categorization engine creates custom rules to fit your spending"
            title="Smart categorization"
        >
            <StaticImage
                alt=""
                src="./images/smart-categorization-illustration.png"
                width={325}
                quality={100}
                placeholder="none"
            />
        </FeaturePanel>
    )
}

export default SmartCategorization
