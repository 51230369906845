import React from "react";

function IconIncome() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill="#49A231" clipPath="url(#clip0_433_3481)">
        <path d="M18.588 10.096a2.181 2.181 0 104.362 0 2.181 2.181 0 00-4.362 0M17.134 4.643a2.181 2.181 0 104.362 0 2.181 2.181 0 00-4.362 0M12.771 8.641a2.181 2.181 0 104.363 0 2.181 2.181 0 00-4.363 0M4.259 11.548v2.27c3.266-.481 3.73-1.789 6.753-1.789h3.928c1.92 0 1.932 2.886 0 2.886h-4.6v.772h7.943l3.61-1.777c1.674-.825 2.999 1.584 1.355 2.465-3.277 1.658-5.294 3.334-9.208 3.334-3.29-.034-4.135.089-5.635-.151l-4.147-.607v2.299H0v-9.703H4.26z"></path>
      </g>
      <defs>
        <clipPath id="clip0_433_3481">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconIncome;
