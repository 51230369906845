import React from "react";
import styled from "styled-components";
import Section from "../../shared/section";
import Grid from "../../shared/grid";
import { H2, H4, H5 } from "../../shared/headings";
import { themeObject } from "../../../global-style";

import IconBudgets from "./icons/IconBudgets";
import IconChanges from "./icons/IconChanges";
import IconCredit from "./icons/IconCredit";
import IconFees from "./icons/IconFees";
import IconFraud from "./icons/IconFraud";
import IconIncome from "./icons/IconIncome";
import IconOverdraft from "./icons/IconOverdraft";
import IconReviews from "./icons/IconReviews";

const alerts = [
    {
        Icon: IconCredit,
        title: "Build better credit over time",
        accentColor: themeObject.color.categoryLimeRGB
    },
    {
        Icon: IconOverdraft,
        title: "Prevent overdraft fees",
        accentColor: themeObject.color.categoryAquaRGB
    },
    {
        Icon: IconBudgets,
        title: "Stick to your budgets",
        accentColor: themeObject.color.categoryTanRGB
    },
    {
        Icon: IconFees,
        title: "Spot unwanted bank fees",
        accentColor: themeObject.color.categoryPinkRGB
    },
    {
        Icon: IconReviews,
        title: "Understand your spending",
        accentColor: themeObject.color.categoryBlueRGB
    },
    {
        Icon: IconFraud,
        title: "Stop fraud in its tracks",
        accentColor: themeObject.color.categoryOrangeRGB
    },
    {
        Icon: IconIncome,
        title: "Know the moment you get paid",
        accentColor: themeObject.color.categoryGreenRGB
    },
    {
        Icon: IconChanges,
        title: "Catch changes to your bills",
        accentColor: themeObject.color.categoryLimeRGB
    },
]

const AlertsSection = () => {
    return (
        <Section>
            <Grid>
                <H2 dense>Gain peace of mind</H2>
                <H4>Copilot’s smart monitoring lets you know when something needs your attention with alerts that help you:</H4>
                <AlertItems>
                    {alerts.map((alert, index) => (
                        <Alert key={`alert-${index}`} title={alert.title} Icon={alert.Icon} accentColor={alert.accentColor} />
                    ))}
                </AlertItems>
            </Grid>
        </Section>
    )
}

const Alert = ({ title, accentColor, Icon }) => {
    return (
        <Card style={{ backgroundColor: `rgba(${accentColor},0.1)`, boxShadow: `inset 0px 1.5px 0px rgba(${accentColor},0.25)` }}>
            <Icon/>
            <AlertDesc>
                <AlertTitle style={{ color: `rgba(${accentColor},1)` }}>{title}</AlertTitle>
            </AlertDesc>
        </Card>
    )
}

const AlertItems = styled.div`
    grid-column: span 12;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    ${props => props.theme.device.tablet} {
        grid-template-columns: repeat(4, 1fr);
    }
    ${props => props.theme.device.laptop} {
        // grid-column: col-start 3 / span 8;
        gap: 32px;
    }
    ${props => props.theme.device.laptopLg} {
        grid-column: col-start 3 / span 8;
        gap: 48px;
    }
`

const Card = styled.div`
    background-color: #051426;
    color: #FFFFFF;
    padding: 16px;
    display: grid;
    align-items: flex-start;
    grid-template-rows: 2rem auto;
    border-radius: 10px;
    ${props => props.theme.device.tablet} {
    }
    ${props => props.theme.device.laptop} {
        padding: 24px;
    }
    ${props => props.theme.device.laptopLg} {
        padding: 32px;
        gap: 1rem;
    }
    & svg {
        width: 24px;
        height: 24px;
        ${props => props.theme.device.laptopLg} {
            width: 36px;
            height: 36px;
        }
    }
`

const AlertTitle = styled(H5)`
    color: ${props => props.theme.color.textHighContrast};
    // font-size: inherit;
    // font-weight: 600;
    // margin-top: 8px;
    display: inline;
    ${props => props.theme.device.laptop} {
        // margin-top: 8px;
        // font-size: ${props => props.theme.fontSize.regular};
    }
`
const AlertDesc = styled.span`
    color: ${props => props.theme.color.textPrimary};
    font-size: ${props => props.theme.fontSize.regular};
    display: inline;
    font-weight: 600;
    // margin-top: 12px;
    ${props => props.theme.device.laptop} {
        // margin-top: 16px;
        // font-size: 1.25em;
    }
`

export default AlertsSection