import React from "react";

function IconOverdraft() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_433_3391)">
        <g
          fill="#56BCBB"
          fillRule="evenodd"
          clipPath="url(#clip1_433_3391)"
          clipRule="evenodd"
        >
          <path d="M16.967 13.338a2.259 2.259 0 000-3.195l-2.322-2.322a2.259 2.259 0 00-3.194 0l-1.394 1.395c-.836.836-2.056 1.095-3.212 1.34-1.04.222-2.028.432-2.637 1.04-.862.863-1.13 2.173-.991 3.527l1.38-1.38a4.42 4.42 0 014.653-1.022l1.043-1.044a1.853 1.853 0 112.621 2.622l-1.044 1.043a4.42 4.42 0 01-1.023 4.651l-1.534 1.534c1.477.226 2.942-.01 3.879-.947.609-.608.819-1.596 1.04-2.636.245-1.156.505-2.376 1.34-3.212l1.395-1.394zm-5.88.666l.766-.766a.353.353 0 10-.5-.5l-.765.766a4.542 4.542 0 01.5.5zm-1.162.163a3.53 3.53 0 01.5.5l-1.741 1.74a.353.353 0 11-.5-.5l1.741-1.74zM8.5 13.47l-1.377 1.377a1.853 1.853 0 002.621 2.621l1.377-1.376a3.483 3.483 0 01-.934 3.241l-3.394 3.394a3.486 3.486 0 01-4.93-4.93l3.394-3.394A3.484 3.484 0 018.5 13.47zm-4.97 4.795a1.976 1.976 0 112.794 2.794l-.4.4a1.976 1.976 0 11-2.794-2.795l.4-.4z"></path>
          <path d="M12.383 12.208c-.431-.431-1.13-.431-1.56 0l-3.17 3.17a1.103 1.103 0 101.56 1.56l3.17-3.17c.43-.43.43-1.13 0-1.56zM15.676 4.79c.22-.22.578-.22.798 0l1.797 1.797a.565.565 0 01-.798.798L15.676 5.59a.565.565 0 010-.799zM17.672 2.793c.22-.22.578-.22.799 0l1.796 1.796a.565.565 0 11-.798.799l-1.797-1.797a.565.565 0 010-.798zM19.673.793c.22-.22.578-.22.799 0l1.796 1.796a.565.565 0 11-.798.799l-1.797-1.797a.565.565 0 010-.798zM16.874 7.983c.22-.22.578-.22.799 0l1.796 1.797a.565.565 0 11-.798.798l-1.797-1.797a.565.565 0 010-.798zM18.87 5.987c.22-.22.578-.22.799 0l1.796 1.797a.565.565 0 01-.798.798L18.87 6.785a.564.564 0 010-.798zM20.87 3.988c.22-.22.578-.22.799 0l1.796 1.797a.565.565 0 01-.798.798L20.87 4.787a.565.565 0 010-.799z"></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_433_3391">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
        <clipPath id="clip1_433_3391">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconOverdraft;
