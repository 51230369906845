import React from "react";

function IconBudgets() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_433_3409)">
        <path
          fill="#CFA436"
          fillRule="evenodd"
          d="M18.004 2.484A11.197 11.197 0 0012 .75C5.787.75.75 5.787.75 12S5.787 23.25 12 23.25 23.25 18.213 23.25 12c0-2.084-.567-4.036-1.555-5.71h-2.37l-.756.718a8.25 8.25 0 11-1.607-1.6l.67-.724.372-2.2zM14.909 7.63a5.25 5.25 0 101.463 1.463l-2.457 2.329a2 2 0 11-1.29-1.322l2.284-2.47z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_433_3409">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconBudgets;
