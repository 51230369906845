import React from "react";
import styled, { css } from "styled-components";
import Grid from "../../../shared/grid";
import cssOutlineMixin from "../../../shared/css-outline-mixin";
import { themeObject } from "../../../../global-style";
import { 
  IntegrationsContent, 
  TransactionRuleContent,
  SpendingContent,
  BudgetContent,
  RecurringsContent,
  InvestmentsContent,
  Percent
} from "./panel-content";
import useStore from "./store";

// const Panels = ({ data, ...props }) => {
//   return(
//     <GridContainer>
//       <CGrid>
//         <Integrations data={data.integrations} />
//         <Rules data={data.rules} />
//         <Budget data={data.budget} />
//         <Spending data={data.spending} />
//         <Investments data={data.investments} />
//         <Recurrings data={data.recurrings} />
//       </CGrid>
//     </GridContainer>
//   )
// }

const Panels = React.memo(function Panels() {
  return(
    <GridContainer>
      <CGrid>
        <Integrations />
        <Rules />
        <Budget />
        <Spending />
        <Investments />
        <Recurrings />
      </CGrid>
    </GridContainer>
  )
});

const Primitive = ({ title, subtitle, AccessoryElement, bgStyle, children, gridRules, ...props }) => {
  return (
    <Base
      bgStyle={bgStyle}
      gridRules={gridRules} 
      {...props}
    >
      <Header>
        <div>
          <h6>{title}</h6>
          {subtitle && <h6 className="muted">{subtitle}</h6>}
        </div>
        {AccessoryElement && <div><AccessoryElement/></div>}
      </Header>
      <Content>
        {children}
      </Content>
    </Base>
  )
}

const Base = styled.div`
  overflow: hidden;
  grid-column: ${props => props.isTwoCol ? 'span 2' : 'span 1'};
  grid-row: ${props => props.isTwoRow ? 'span 2' : 'span 1'};
  border-radius: 10px;
  // ${props => props.bgStyle.bg};
  // background-color: #5D0FAE;
  // background-color: color(display-p3 0.286 0 0.708);
  background-color: #05162C;
  font-size: 0.875rem;
  font-weight: 600;
  ${props => props.gridRules};
  ${cssOutlineMixin};
  &:after {
    // box-shadow: inset 0px 1.5px 0px ${props => props.theme.color.textPrimary}, inset 0px -1px 0px ${props => props.theme.color.textPrimary};
    border-bottom: 0.5px solid ${props => props.theme.color.textPrimary};
  }
`
const Content = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 83px);
  display: block;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 68px;
  padding: 18px 20px 0 20px;
  & h6 {
    font-size: 10pt;
    font-weight: 600;
    text-transform: uppercase;
  }
  & h6.muted {
    text-transform: none;
    color: ${props => props.theme.color.textSecondary};
    // color: #D1416B;
    mix-blend-mode: screen;
    mix-blend-mode: plus-lighter;
  }
`

export const Integrations = () => {
  const currentPersona = useStore((state) => state.persona);
  const data = currentPersona.integrations;
  const amazonIntegration = data.amazon.length > 0;
  const venmoIntegration = data.venmo.length > 0;
  let integration;
  if (amazonIntegration) {
    integration = {provider: "amazon", data: data.amazon};
  } else if (venmoIntegration) {
    integration = {provider: "venmo", data: data.venmo};
  } else {
    integration = {provider: null, data: null};
  }
  const gridRules = css`
    display: none;
    ${props => props.theme.device.tablet} {
      display: block;
      grid-area: 3 / 2 / span 1 / span 1;
    }
    ${props => props.theme.device.laptopLg} {
      grid-area: 2 / 4 / span 1 / span 1;
    }
  `
  function getTitle() {
    if (integration && amazonIntegration) return "Amazon";
    if (integration && venmoIntegration) return "Venmo";
    return "Integrations";
  }
  function getSubtitle() {
    if (integration && amazonIntegration) return "Last order";
    if (integration && venmoIntegration) return "Recent transactions";
    return "Amazon, Venmo, etc.";
  }
  return (
    <Primitive
      title={getTitle()}
      subtitle={getSubtitle()}
      gridRules={gridRules}
    >
      <IntegrationsContent type={integration.provider} data={integration.data} />
    </Primitive>
  )
}

export const Rules = () => {
  const currentPersona = useStore((state) => state.persona);
  const data = currentPersona.rules;
  const gridRules = css`
    grid-area: 3 / 1 / span 1 / span 1;
    ${props => props.theme.device.tablet} {
      grid-area: 2 / 1 / span 1 / span 1;
    }
    ${props => props.theme.device.laptopLg} {
      grid-area: 2 / 1 / span 1 / span 1;
    }
  `
  return (
    <Primitive
      title="Transaction rules"
      gridRules={gridRules}
    >
      <TransactionRuleContent data={data} />
    </Primitive>
  )
}

export const Budget = () => {
  const currentPersona = useStore((state) => state.persona);
  const data = currentPersona.budget;
  const gridRules = css`
    grid-area: 1 / 1 / span 1 / span 1;
    ${props => props.theme.device.tablet} {
      grid-area: 1 / 1 / span 1 / span 1;
    }
    ${props => props.theme.device.laptopLg} {
      grid-area: 1 / 1 / span 1 / span 1;
    }
  `
  const budgetingDisabled = data.budgeting_disabled;
  return (
    <Primitive
      title={budgetingDisabled ? "Spent" : "Budget"}
      gridRules={gridRules}
    >
      <BudgetContent data={data} />
    </Primitive>
  )
}

export const Spending = () => {
  const currentPersona = useStore((state) => state.persona);
  const data = currentPersona.spending;
  const gridRules = css`
    grid-area: 2 / 1 / span 1 / span 1;
    ${props => props.theme.device.tablet} {
      grid-area: 1 / 2 / span 2 / span 1;
    }
    ${props => props.theme.device.laptopLg} {
      grid-area: 1 / 2 / span 2 / span 1;
    }
  `
  function Indicator() { 
    if(data.over) {
      return <div style={{color: themeObject.color.accentRed}}><Percent>{data.status}</Percent> OVER</div> 
    } else {
      return <div style={{color: themeObject.color.accentGreen}}><Percent>{data.status}</Percent> UNDER</div> 
    }
  }
  
  return (
    <Primitive
      title="Spending"
      gridRules={gridRules}
      AccessoryElement={Indicator}
    >
      <SpendingContent data={data} />
    </Primitive>
  )
}

export const Investments = () => {
  const currentPersona = useStore((state) => state.persona);
  const data = currentPersona.investments;
  const gridRules = css`
    grid-area: 4 / 1 / span 1 / span 1;
    ${props => props.theme.device.tablet} {
      grid-area: 3 / 1 / span 1 / span 1;
    }
    ${props => props.theme.device.laptopLg} {
      grid-area: 2 / 3 / span 1 / span 1;
    }
  `
  return (
    <Primitive
      title="Investments"
      gridRules={gridRules}
    >
      <InvestmentsContent data={data} />
    </Primitive>
  )
}

export const Recurrings = () => {
  const currentPersona = useStore((state) => state.persona);
  const data = currentPersona.recurrings;
  const gridRules = css`
    display: none;
    ${props => props.theme.device.laptopLg} {
      display: block;
      grid-area: 1 / 3 / span 1 / span 2;
    }
  `
  return (
    <Primitive
      title="Recurrings"
      gridRules={gridRules}
    >
      <RecurringsContent data={data} />
    </Primitive>
  )
}

const CGrid = styled(Grid)`
    gap: 10px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 230px);
    border-radius: 10px;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
    ${props => props.theme.device.tablet} {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 230px);
        gap: 8px;
    }
    ${props => props.theme.device.laptop} {
        gap: 12px;
    }
    ${props => props.theme.device.laptopLg} {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 230px);
        gap: 16px;
    }
`
const GridContainer = styled.div`
    overflow: hidden;
    margin: 0 auto;
    max-width: 351px;
    ${props => props.theme.device.tablet} {
        max-width: 688px;
    }
    ${props => props.theme.device.laptopLg} {
        max-width: 1336px;
    }
`

export default Panels