import React from "react";

const IconBase = ({ color, path }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="34"
      fill="none"
      viewBox="0 0 33 34"
    >
      <rect
        width="32"
        height="32"
        x="0.5"
        y="1.108"
        fill={color}
        rx="16"
      ></rect>
      <rect
        width="32"
        height="32"
        x="0.5"
        y="1.108"
        stroke="#D2DAE4"
        rx="16"
        style={{ mixBlendMode: "overlay" }}
      ></rect>
      <path
        fill="#fff"
        d={path}
      ></path>
    </svg>
  )
}

export const RobinhoodIcon = () => {
  return (
    <IconBase 
      color="#005709" 
      path="M20.714 7.3c-.769-.016-1.683.15-2.718.492a1.03 1.03 0 00-.389.25 49.96 49.96 0 00-3.045 3.119l-.074.082a.074.074 0 00-.008.085.07.07 0 00.076.035l.11-.023c1.56-.333 3.135-.59 4.68-.759a.365.365 0 01.401.366c-.026 1.534.03 3.076.166 4.582l.01.098c.003.03.023.055.054.063l.017.002c.023 0 .046-.01.06-.03l.057-.081a43.196 43.196 0 012.814-3.592.58.58 0 00.162-.323c.314-2.007-.17-3.492-.603-3.868-.377-.326-.922-.479-1.77-.497zm-2.39 4.068l-.096.013c-1.49.205-3 .49-4.486.845a.594.594 0 00-.263.16 50.53 50.53 0 00-3.131 4.17c-.049.072-.054.246-.054.246s.243 1.872.598 3.25c-.879 2.527-1.663 5.855-1.663 5.855a.074.074 0 00.011.063c.013.018.034.03.057.03h.5c.032 0 .06-.018.07-.047l.035-.095a46.4 46.4 0 011.735-4.105c.15-.312.465-.942.465-.942.052-.102.295-.567.35-.671l.009-.019a46.199 46.199 0 015.864-8.562l.062-.072a.075.075 0 00.01-.082.073.073 0 00-.073-.037zm.687.576a.07.07 0 00-.08.02l-.062.072a45.411 45.411 0 00-6.617 10.106l-.04.085a.073.073 0 00.011.08.073.073 0 00.052.023c.009 0 .02-.001.03-.005l.087-.038a43.155 43.155 0 014.516-1.586.34.34 0 00.209-.172c.661-1.29 2.198-3.786 2.198-3.786.04-.057.03-.14.03-.14s-.27-2.987-.286-4.498l-.002-.095a.072.072 0 00-.046-.066zM15.083 21.97l-.123.042c-.8.266-1.983.674-3.045 1.162-.056.027-.094.1-.094.1l-.071.167-.004.008c-.12.27-.284.678-.355.843l-.054.131a.053.053 0 00.013.06.052.052 0 00.06.01l.129-.062c.29-.138.658-.347 1.044-.53l.013-.007 2.059-.978s.08-.043.12-.123l.373-.748a.055.055 0 00-.007-.06.056.056 0 00-.058-.016z"
    />
  )
}

export const CoinbaseIcon = () => {
  return (
    <IconBase 
      color="#003D98" 
      path="M16.524 22.006a4.905 4.905 0 110-9.812 4.903 4.903 0 014.833 4.088H26.3c-.418-5.039-4.633-9-9.781-9-5.42 0-9.818 4.397-9.818 9.818 0 5.42 4.397 9.818 9.818 9.818 5.148 0 9.363-3.961 9.781-9h-4.948a4.897 4.897 0 01-4.827 4.088z"
    />
  )
}

export const WealthfrontIcon = () => {
  return (
    <IconBase 
      color="#330052" 
      path="M24.737 24.911v-.056a.387.387 0 00-.085-.092c-4.228-3.447-3.697-10.658-2.496-17.35v-.001c.048-.27.08-.449-.133-.474-1.367-.16-5.918.583-7.225.94-.34.092-.37.19-.376.405-.358 13.397 7.88 16.203 10.12 16.639.05.01.162.023.195-.01zM12.843 12.044c-1.677.327-3.23.964-4.573 1.537-.118.034-.307.235-.258.484.53 2.707 3.114 10.339 12.993 10.933.227.01.457-.031.278-.142-.197-.151-.467-.303-.792-.485-1.877-1.051-5.564-3.118-7.216-11.942l-.006-.045c-.018-.143-.056-.45-.426-.34zm-7.39 8.217c.544-.513 2.061-1.508 2.65-1.863.178-.107.258.006.416.343.635 1.238 1.583 2.54 2.514 3.396 1.565 1.44 3.253 2.13 3.813 2.36.106.044.172.07.188.082.16.118.158.211-.147.222-6.442.217-9.035-2.591-9.474-4.194l-.002-.004c-.046-.17-.066-.242.041-.342z"
    />
  )
}

export const ETradeIcon = () => {
  return (
    <IconBase 
      color="#6633CC" 
      path="M7.213 15.091h5.984l-2.991-5.183L13.512 8l2.991 5.181L19.495 8l3.307 1.908-2.992 5.183h5.983v3.818H19.81l2.992 5.18L19.495 26l-2.992-5.183L13.513 26l-3.307-1.91 2.99-5.181H7.214V15.09z"
    />
  )
}

export const FidelityIcon = () => {
  return (
    <IconBase 
      color="#128741" 
      path="M18.731 18.459h-3.743l1.463-4.704-2.482 3.98.744.525h-1.07l-.939 1.497L8.69 21.86l-.067.021c-.022-.012-.009-.033 0-.033l5.007-4.255-6.378.744a.042.042 0 010-.046l6.18-1.81-6.214-1.82c-.02-.012-.02-.033 0-.042l6.412.677-.516-.427L8.61 11.17c0-.008.021-.021.03-.021l5.65 3.083-2.986-5.688c.009-.009.021-.017.03-.009l3.95 5.139-.427-6.103-.03-.546c.009-.021.03-.013.043 0l1.137 4.483.533 1.93 1.916-6.227c.021-.012.043 0 .043.013l-.796 6.408 4.154-4.877c.021-.013.021.008.021.021l-3.18 5.609.896-.449 4.864-2.47c.013-.02.055-.008.047.013l-.44.351-4.69 3.532 6.55-.428c.01.013.01.034-.012.034l-6.387 1.544 6.146 2.153.042.034c0 .012-.008.02-.03.02l-6.521-.993 5.041 4.352c.013.021 0 .043-.02.043l-3.85-2.145 2.492 4.179.274.427c1.583-1.278 2.748-3 3.346-4.944.897-2.821.486-5.96-.994-8.434a10.49 10.49 0 00-6.002-4.67c-2.098-.647-4.745-.558-6.725.263-2.516.93-4.88 3.193-5.862 5.697a10.447 10.447 0 00-.025 7.922c.96 2.504 3.256 4.733 5.74 5.697l.253-.655 1.992-6.29h5.004l-1.096-.71z"
    />
  )
}