import React from "react";

function IconRules() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="36"
      fill="none"
      viewBox="0 0 37 36"
    >
      <g clipPath="url(#clip0_359_1803)">
        <g filter="url(#filter0_i_359_1803)">
          <path
            fill="#6AA7F8"
            d="M8.502 18a3.375 3.375 0 10-6.75 0 3.375 3.375 0 006.75 0z"
          ></path>
        </g>
        <g filter="url(#filter1_i_359_1803)">
          <path
            fill="#6AA7F8"
            d="M34.752 18a3.375 3.375 0 10-6.75 0 3.375 3.375 0 006.75 0z"
          ></path>
        </g>
        <g filter="url(#filter2_i_359_1803)">
          <path
            fill="#6AA7F8"
            d="M8.502 4.875a3.375 3.375 0 10-6.75 0 3.375 3.375 0 006.75 0z"
          ></path>
        </g>
        <g filter="url(#filter3_i_359_1803)">
          <path
            fill="#6AA7F8"
            d="M34.752 4.875a3.375 3.375 0 10-6.75 0 3.375 3.375 0 006.75 0z"
          ></path>
        </g>
        <g filter="url(#filter4_i_359_1803)">
          <path
            fill="#6AA7F8"
            d="M8.502 31.125a3.375 3.375 0 10-6.75 0 3.375 3.375 0 006.75 0z"
          ></path>
        </g>
        <g filter="url(#filter5_i_359_1803)">
          <path
            fill="#6AA7F8"
            d="M34.752 31.125a3.375 3.375 0 10-6.75 0 3.375 3.375 0 006.75 0z"
          ></path>
        </g>
        <path
          stroke="#6AA7F8"
          strokeLinecap="round"
          strokeWidth="2"
          d="M11.502 18.375h1.014a3.375 3.375 0 002.95-1.736l5.572-10.028a3.375 3.375 0 012.95-1.736h1.014M11.502 31.5h1.014a3.375 3.375 0 002.95-1.736l5.572-10.028A3.375 3.375 0 0123.988 18h1.014"
        ></path>
        <path
          stroke="#6AA7F8"
          strokeLinecap="round"
          strokeWidth="2"
          d="M11.502 4.875h.444a3.375 3.375 0 013.249 2.46l6.114 21.705a3.375 3.375 0 003.249 2.46h.444"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_i_359_1803"
          width="6.75"
          height="6.75"
          x="1.752"
          y="14.625"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_1803"></feBlend>
        </filter>
        <filter
          id="filter1_i_359_1803"
          width="6.75"
          height="6.75"
          x="28.002"
          y="14.625"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_1803"></feBlend>
        </filter>
        <filter
          id="filter2_i_359_1803"
          width="6.75"
          height="6.75"
          x="1.752"
          y="1.5"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_1803"></feBlend>
        </filter>
        <filter
          id="filter3_i_359_1803"
          width="6.75"
          height="6.75"
          x="28.002"
          y="1.5"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_1803"></feBlend>
        </filter>
        <filter
          id="filter4_i_359_1803"
          width="6.75"
          height="6.75"
          x="1.752"
          y="27.75"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_1803"></feBlend>
        </filter>
        <filter
          id="filter5_i_359_1803"
          width="6.75"
          height="6.75"
          x="28.002"
          y="27.75"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_1803"></feBlend>
        </filter>
        <clipPath id="clip0_359_1803">
          <path
            fill="#fff"
            d="M0 0H36V36H0z"
            transform="translate(.252)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconRules;
