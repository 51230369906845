import { themeObject } from "../../../../../global-style";
import { CoinbaseIcon, RobinhoodIcon } from "./brokerage-logos";
import { MateoBudget } from "./budget-graphs";
import { MateoRecurring } from "./recurring-graphs";

const Mateo = {
  "integrations": {
    "amazon": [],
    "venmo": [{
      "category": { "name": "Drinks", "color": themeObject.color.categoryPurple, "emoji": "🍻" },
      "description": "🥃🥃",
      "emoji_only_description": true,
      "amount": 18
    }, 
    {
      "category": { "name": "Weekends", "color": themeObject.color.categoryBlue, "emoji": "⛺️" },
      "description": "Campsite to Ben L",
      "emoji_only_description": false,
      "amount": 38
    }]
  },
  "amazon": {
    "purchases": [{
      "category": { "name": "Doge", "color": "#A01F8A", "emoji": "🐶" },
      "quantity": 1,
      "product": "Purina Pro Plan with Probiotics Shredded Blend High Protein",
      "price": 17.84
    }, 
    {
      "category": { "name": "Shops", "color": "#2E6B1F", "emoji": "🛍" },
      "quantity": 2,
      "product": "USB C Female to USB Male Adapter 2 Pack",
      "price": 24.98
    }]
  },
  "rules": [{
    "rule": "Parks NY fee",
    "category": { "name": "Weekends", "color": themeObject.color.categoryBlue, "emoji": "⛺️" }
  },
  {
    "rule": "Wifi to Todd",
    "category": { "name": "Utilities", "color": themeObject.color.categoryViolet, "emoji": "⚡️" }
  },
  {
    "rule": "Wolffs Biergarten",
    "category": { "name": "Drinks", "color": themeObject.color.categoryPurple, "emoji": "🍻" }
  }
  ],
  "budget": {
    "left": 438,
    "total": "3,215",
    "graph": MateoBudget,
    "budgeting_disabled": false,
  },
  "spending": {
    "status": "9.53",
    "over": false,
    "budgeting_disabled": false,
    "categories": [
      { "name": "Weekends", "color": themeObject.color.categoryBlue, "emoji": "⛺️", "performance": 62 },
      { "name": "Groceries", "color": themeObject.color.categoryRed, "emoji": "🥗", "performance": 56 },
      { "name": "Going out", "color": themeObject.color.categoryPurple, "emoji": "🍣", "performance": 56 },
      { "name": "Rent", "color": themeObject.color.categoryViolet, "emoji": "🛌", "performance": 50 },
      { "name": "Streaming", "color": themeObject.color.categoryAqua, "emoji": "🏫", "performance": 50 },
      { "name": "Utilities", "color": themeObject.color.categoryViolet, "emoji": "⚡️", "performance": 50 },
      { "name": "Drinks", "color": themeObject.color.categoryPurple, "emoji": "🍻", "performance": 40 },
      { "name": "Random", "color": themeObject.color.categoryOrange, "emoji": "🤷🏽", "performance": 34 },
      { "name": "Investing", "color": themeObject.color.categoryGreen, "emoji": "🤑", "performance": 31 },
      { "name": "Transportation", "color": themeObject.color.categoryGreen, "emoji": "👟", "performance": 2 },
      { "name": "Gym", "color": themeObject.color.categoryGreen, "emoji": "💪🏾", "performance": 2 },
    ]
  },
  "investments": [
    { "name": "Robinhood", "balance": "17,813.01", "performance": 0.89, icon: RobinhoodIcon },
    { "name": "Coinbase", "balance": "8,438.54", "performance": 0.24, icon: CoinbaseIcon }
  ],
  "recurrings": {
    "left": 245,
    "total": 700,
    "graph": MateoRecurring,
    "schedule": [
      { "name": "Rent", "emoji": "🛌", "balance": "1,050", "date": "1st", "paid": true },
      { "name": "Electric", "emoji": "⚡️", "balance": "30", "date": "15th", "paid": false },
      { "name": "Patreon", "emoji": "🥰", "balance": "15", "date": "7th", "paid": true },
      { "name": "ESPN+", "emoji": "🏈", "balance": "10", "date": "23rd", "paid": false }
    ]
  }
}

export default Mateo;