import styled from "styled-components";

export const SimpleTagContainer = styled.div`
  display: flex;
  grid-column: col-start / span 12;
  margin: 0 auto 0.5rem;
  text-align: center;
  padding: 8px 12px 8px 20px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 55px;
  border: 1px solid #6aa7f86b;
  align-items: center;
`
export const SimpleTag = styled.a`
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.24px;
    background: ${props => props.theme.color.textLink};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-right: 10px;
    text-wrap: balance;
}
`