import React from "react";

function IconRebalancing() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="36"
      fill="none"
      viewBox="0 0 37 36"
    >
      <path
        fill="#6AA7F8"
        d="M14.252 34.125h-2a2 2 0 01-2-2V14h6v18.125a2 2 0 01-2 2z"
      ></path>
      <g filter="url(#filter0_i_359_1758)">
        <path
          fill="#6AA7F8"
          d="M12.252 1h2a2 2 0 012 2v5h-6V3a2 2 0 012-2z"
        ></path>
      </g>
      <g filter="url(#filter1_i_359_1758)">
        <path
          fill="#6AA7F8"
          d="M28.252 4h2a2 2 0 012 2v2h-6V6a2 2 0 012-2z"
        ></path>
      </g>
      <path
        fill="#6AA7F8"
        d="M30.752 34.125h-3a1.5 1.5 0 01-1.5-1.5V14h6v18.625a1.5 1.5 0 01-1.5 1.5z"
      ></path>
      <g filter="url(#filter2_i_359_1758)">
        <path
          fill="#6AA7F8"
          d="M6.252 34.125h-2a2 2 0 01-2-2V16a2 2 0 012-2h2a2 2 0 012 2v16.125a2 2 0 01-2 2z"
        ></path>
      </g>
      <g filter="url(#filter3_i_359_1758)">
        <path
          fill="#6AA7F8"
          d="M22.252 34h-2a2 2 0 01-2-2V20a2 2 0 012-2h2a2 2 0 012 2v12a2 2 0 01-2 2z"
        ></path>
      </g>
      <g filter="url(#filter4_i_359_1758)">
        <path
          stroke="#6AA7F8"
          strokeLinecap="round"
          strokeWidth="2"
          d="M2.252 11h32"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_i_359_1758"
          width="6"
          height="7"
          x="10.252"
          y="1"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_1758"></feBlend>
        </filter>
        <filter
          id="filter1_i_359_1758"
          width="6"
          height="4"
          x="26.252"
          y="4"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_1758"></feBlend>
        </filter>
        <filter
          id="filter2_i_359_1758"
          width="6"
          height="20.125"
          x="2.252"
          y="14"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_1758"></feBlend>
        </filter>
        <filter
          id="filter3_i_359_1758"
          width="6"
          height="16"
          x="18.252"
          y="18"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_1758"></feBlend>
        </filter>
        <filter
          id="filter4_i_359_1758"
          width="34"
          height="2"
          x="1.252"
          y="10"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="0.5"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_1758"></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default IconRebalancing;
