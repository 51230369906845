import * as React from "react";
import { H2 } from "../../../shared/headings";
import Section from "../../../shared/section";
import Grid from "../../../shared/grid";
import styled from "styled-components";
import { DoubleFeature, FeatureCallout } from "../content-elements";
import IconCategories from "./icons/IconCategories";
import IconControl from "./icons/IconControl";
import IconIntegrations from "./icons/IconIntegrations";
import IconML from "./icons/IconML";
import IconRebalancing from "./icons/IconRebalancing";
import IconRules from "./icons/IconRules";
import DailySnapshots from "./daily-snapshots";
import SmartCategorization from "./smart-categorization";
import Rollovers from "./rollovers";
import CashFlow from "./cash-flow.js";

const iconSection = [
  { 
    Icon: IconRebalancing, 
    title: "Budget rebalancing", 
    // description: "Smart adjustment suggestions a tap away" 
    description: "Tap to approve smart adjustment suggestions" 
  },
  { 
    Icon: IconML, 
    title: "Machine learning", 
    description: "Our categorization engine is a quick study" 
  },
  { 
    Icon: IconCategories, 
    title: "Custom categories", 
    description: "Create your own spending categories and groups" 
  },
  { 
    Icon: IconRules, 
    title: "Categorization rules", 
    description: "Define custom rules for the trickiest transactions" 
  },
  { 
    Icon: IconIntegrations, 
    title: "Integrations", 
    // description: "Copilot can integrate with your Amazon and Venmo" 
    description: "See transaction details from Amazon and Venmo"
  },
  { 
    Icon: IconControl, 
    title: "Full transaction control", 
    description: "Make adjustments to any part of a transaction" 
  },
]

const SpendTracking = () => {
  return (
    <Section id="features">
        <Grid>
          <H2 dense>Track your spending and <ConditionalLineBreak>boost your savings</ConditionalLineBreak></H2>
          <DoubleFeature>
            <DailySnapshots />
            <SmartCategorization />
          </DoubleFeature>
          <DoubleFeature>
            <Rollovers />
            <CashFlow />
          </DoubleFeature>
          <SecondaryFeatures>
            { iconSection.map((feature, index) => {
              return <FeatureCallout key={index} Icon={feature.Icon} title={feature.title} description={feature.description} />
            }) }
          </SecondaryFeatures>
        </Grid>
    </Section>
  )
}

const ConditionalLineBreak = styled.span`
  display: inline;
  @media screen and (min-width: 1106px) {
      display: block;
  }
`

const SecondaryFeatures = styled.div`
  grid-column: span 12;
  margin-top: 56px;
  ${props => props.theme.device.tablet} {
    grid-column: col-start 3 / span 8;
    margin-top: 80px;
  }
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 8px;
`

export default SpendTracking
