import React from "react";

function IconPriceChanges() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="36"
      fill="none"
      viewBox="0 0 37 36"
    >
      <g clipPath="url(#clip0_359_2383)">
        <path
          fill="#6AA7F8"
          d="M10.33 15.566c0-2.261-1.479-2.873-3.842-3.587-1.122-.34-1.479-.629-1.479-1.037 0-.425.34-.68 1.054-.68.697 0 1.513.238 2.482.918l1.581-1.972a7.43 7.43 0 00-3.094-1.445V5.706H5.196v1.989c-2.006.272-3.162 1.632-3.162 3.434 0 2.21 1.717 2.907 3.655 3.485 1.31.391 1.632.68 1.632 1.173 0 .442-.39.765-1.054.765-.816 0-1.938-.374-2.89-1.275l-1.632 1.989a6.163 6.163 0 003.434 1.768v1.955H7v-1.938c2.022-.272 3.331-1.547 3.331-3.485zM22.332 5.944c-5.04 0-8.964 3.348-8.964 8.352 0 5.184 3.528 7.992 7.416 7.992a9.048 9.048 0 003.996-.936l.036.036c-1.332 2.916-3.996 4.788-8.424 6.012l1.62 4.536c8.352-1.584 13.428-8.244 13.428-15.84 0-6.048-3.672-10.152-9.108-10.152zm-3.384 8.532c0-1.872 1.332-3.384 3.456-3.384 1.944 0 3.492 1.728 3.492 4.464v.288c-.9 1.116-2.268 1.872-3.528 1.872-2.124 0-3.42-1.332-3.42-3.24z"
        ></path>
        <path
          stroke="#010912"
          strokeLinecap="round"
          strokeWidth="2.5"
          d="M4.475 27.247l29.023-24.47M4.475 31.546l29.023-24.47"
        ></path>
        <g filter="url(#filter0_i_359_2383)">
          <path
            stroke="#6AA7F8"
            strokeLinecap="round"
            strokeWidth="2"
            d="M4.431 29.597L33.85 4.822"
          ></path>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i_359_2383"
          width="31.419"
          height="26.776"
          x="3.431"
          y="3.821"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dx="0.5" dy="0.5"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_2383"></feBlend>
        </filter>
        <clipPath id="clip0_359_2383">
          <path
            fill="#fff"
            d="M0 0H36V36H0z"
            transform="translate(.252)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconPriceChanges;
