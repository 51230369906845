import React from "react";

function IconMarkRecurring() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="36"
      fill="none"
      viewBox="0 0 37 36"
    >
      <g clipPath="url(#clip0_359_2359)">
        <g fill="#6AA7F8" filter="url(#filter0_i_359_2359)">
          <path d="M3.252 2.714a.75.75 0 011.085-.67l2.33 1.164a.75.75 0 00.67 0l3.08-1.54a.75.75 0 01.67 0l3.08 1.54a.75.75 0 00.67 0l3.08-1.54a.75.75 0 01.67 0l3.08 1.54a.75.75 0 00.67 0l2.33-1.165a.75.75 0 011.085.67V16.25h-22.5V2.714zM3.252 33.287V16.25h22.5v17.037a.75.75 0 01-1.085.67l-2.33-1.164a.75.75 0 00-.67 0l-3.08 1.54a.75.75 0 01-.67 0l-3.08-1.54a.75.75 0 00-.67 0l-3.08 1.54a.75.75 0 01-.67 0l-3.08-1.54a.75.75 0 00-.67 0l-2.33 1.165a.75.75 0 01-1.085-.671z"></path>
        </g>
        <rect
          width="22"
          height="19"
          x="13.252"
          y="8"
          fill="#6AA7F8"
          stroke="#010912"
          strokeWidth="2"
          rx="4"
        ></rect>
        <g filter="url(#filter1_i_359_2359)">
          <rect
            width="20"
            height="17"
            x="14.252"
            y="9"
            fill="#6AA7F8"
            rx="3"
          ></rect>
          <path
            fill="#010912"
            d="M20.302 22.678h2.39V19.32h.895l2.142 3.358h2.874l-2.478-3.696c1.29-.528 1.965-1.613 1.965-3.124 0-2.082-1.144-3.593-3.725-3.593h-4.063v10.413zm2.39-5.5v-2.757h1.277c1.1 0 1.584.587 1.584 1.379 0 .865-.572 1.378-1.628 1.378h-1.232z"
          ></path>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i_359_2359"
          width="22.5"
          height="32.823"
          x="3.252"
          y="1.589"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_2359"></feBlend>
        </filter>
        <filter
          id="filter1_i_359_2359"
          width="20"
          height="17"
          x="14.252"
          y="9"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_2359"></feBlend>
        </filter>
        <clipPath id="clip0_359_2359">
          <path
            fill="#fff"
            d="M0 0H36V36H0z"
            transform="translate(.252)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconMarkRecurring;
