import AlertsSection from "../alerts";
import Customization from "./customization";
import Intelligence from "./intelligence";
import Investments from "./investments";
import React from "react";
import Recurrings from "./recurrings";
import SpendTracking from "./spend-tracking";

const Features = () => {
  return (
    <>
      <Intelligence/>
      <SpendTracking/>
      <Investments/>
      <Recurrings/>
      <Customization/>
      <AlertsSection/>
    </>
  )
}

export default Features