import React from "react";
import { FeaturePanel } from "../content-elements";
import { StaticImage } from "gatsby-plugin-image";

const Rollovers = () => {
    return (
        <FeaturePanel 
            description="Set budgets to carry your remaining balance from one month to the next"
            title="Rollovers"
        >
            <StaticImage
                alt=""
                src="./images/rollovers-illustration.png"
                width={330}
                quality={100}
                placeholder="none"
            />
        </FeaturePanel>
    )
}

export default Rollovers
