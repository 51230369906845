import React from "react";

export const SBIcon = ({ fg, bg }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="71"
      height="71"
      fill="none"
      viewBox="0 0 71 71"
      style={{height: "initial"}}
    >
      <path
        fill="#00080F"
        d="M1 27.578c0-9.303 0-13.955 1.81-17.508a16.611 16.611 0 017.26-7.26C13.623 1 18.275 1 27.578 1h15.844c9.303 0 13.955 0 17.508 1.81a16.611 16.611 0 017.26 7.26C70 13.623 70 18.275 70 27.578v15.844c0 9.303 0 13.955-1.81 17.508a16.611 16.611 0 01-7.26 7.26C57.377 70 52.725 70 43.422 70H27.578c-9.303 0-13.955 0-17.508-1.81a16.611 16.611 0 01-7.26-7.26C1 57.377 1 52.725 1 43.422V27.578z"
      ></path>
      <path
        stroke="#5397D9"
        strokeOpacity="0.4"
        d="M43.422.5H27.554c-4.631 0-8.137 0-10.927.228-2.8.229-4.925.69-6.784 1.637a17.111 17.111 0 00-7.478 7.478C1.418 11.702.957 13.826.728 16.627.5 19.417.5 22.923.5 27.554V43.446c0 4.631 0 8.137.228 10.927.229 2.8.69 4.925 1.637 6.784a17.111 17.111 0 007.478 7.478c1.859.947 3.983 1.408 6.784 1.637 2.79.228 6.296.228 10.927.228H43.446c4.631 0 8.137 0 10.927-.228 2.8-.229 4.925-.69 6.784-1.637a17.112 17.112 0 007.478-7.478c.947-1.859 1.408-3.983 1.637-6.784.228-2.79.228-6.296.228-10.927V27.554c0-4.631 0-8.137-.228-10.927-.229-2.8-.69-4.925-1.637-6.784a17.111 17.111 0 00-7.478-7.478C59.298 1.418 57.174.957 54.373.728 51.583.5 48.077.5 43.446.5h-.024z"
      ></path>
      <path
        fill="#55A9FF"
        fillRule="evenodd"
        d="M52.075 17.585c1.084-.396 2.136.658 1.74 1.742L40.737 55.162c-.618 1.694-2.969 1.809-3.748.182l-6.575-13.722a1.358 1.358 0 00-.638-.638l-13.718-6.57c-1.627-.78-1.513-3.135.181-3.753l35.836-13.075z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export const LucasIcon = ({ fg, bg }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="71"
      height="71"
      fill="none"
      viewBox="0 0 71 71"
      style={{height: "initial"}}
    >
      <path
        fill="#FFFFFF"
        d="M1 27.578c0-9.303 0-13.955 1.81-17.508a16.611 16.611 0 017.26-7.26C13.623 1 18.275 1 27.578 1h15.844c9.303 0 13.955 0 17.508 1.81a16.611 16.611 0 017.26 7.26C70 13.623 70 18.275 70 27.578v15.844c0 9.303 0 13.955-1.81 17.508a16.611 16.611 0 01-7.26 7.26C57.377 70 52.725 70 43.422 70H27.578c-9.303 0-13.955 0-17.508-1.81a16.611 16.611 0 01-7.26-7.26C1 57.377 1 52.725 1 43.422V27.578z"
      ></path>
      <path
        fill="#0068D2"
        fillRule="evenodd"
        d="M52.075 17.585c1.084-.396 2.136.658 1.74 1.742L40.737 55.162c-.618 1.694-2.969 1.809-3.748.182l-6.575-13.722a1.358 1.358 0 00-.638-.638l-13.718-6.57c-1.627-.78-1.513-3.135.181-3.753l35.836-13.075z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export const EmilyIcon = ({ fg, bg }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="71"
      height="71"
      fill="none"
      viewBox="0 0 71 71"
      style={{height: "initial"}}
    >
      <path
        fill="#0263C4"
        d="M1 27.578c0-9.303 0-13.955 1.81-17.508a16.611 16.611 0 017.26-7.26C13.623 1 18.275 1 27.578 1h15.844c9.303 0 13.955 0 17.508 1.81a16.611 16.611 0 017.26 7.26C70 13.623 70 18.275 70 27.578v15.844c0 9.303 0 13.955-1.81 17.508a16.611 16.611 0 01-7.26 7.26C57.377 70 52.725 70 43.422 70H27.578c-9.303 0-13.955 0-17.508-1.81a16.611 16.611 0 01-7.26-7.26C1 57.377 1 52.725 1 43.422V27.578z"
      ></path>
      <path
        fill="#FFFFFF"
        fillRule="evenodd"
        d="M52.075 17.585c1.084-.396 2.136.658 1.74 1.742L40.737 55.162c-.618 1.694-2.969 1.809-3.748.182l-6.575-13.722a1.358 1.358 0 00-.638-.638l-13.718-6.57c-1.627-.78-1.513-3.135.181-3.753l35.836-13.075z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export const MateoIcon = () => {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="71"
      height="71"
      fill="none"
      viewBox="0 0 71 71"
      style={{height: "initial"}}
    >
      <mask
        id="mask0_573_4117"
        style={{ maskType: "alpha" }}
        width="69"
        height="69"
        x="1"
        y="1"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#0263C4"
          d="M1 27.578c0-9.303 0-13.955 1.81-17.508a16.611 16.611 0 017.26-7.26C13.623 1 18.275 1 27.578 1h15.844c9.303 0 13.955 0 17.508 1.81a16.611 16.611 0 017.26 7.26C70 13.623 70 18.275 70 27.578v15.844c0 9.303 0 13.955-1.81 17.508a16.611 16.611 0 01-7.26 7.26C57.377 70 52.725 70 43.422 70H27.578c-9.303 0-13.955 0-17.508-1.81a16.611 16.611 0 01-7.26-7.26C1 57.377 1 52.725 1 43.422V27.578z"
        ></path>
      </mask>
      <g mask="url(#mask0_573_4117)">
        <path
          fill="#27C119"
          d="M-0.686 1H71.68499999999999V12.5H-0.686z"
        ></path>
        <path
          fill="#FFB700"
          d="M-0.686 12.5H71.68499999999999V24H-0.686z"
        ></path>
        <path
          fill="#FF7B00"
          d="M-0.686 24H71.68499999999999V35.5H-0.686z"
        ></path>
        <path
          fill="#F5192C"
          d="M-0.686 35.5H71.68499999999999V47H-0.686z"
        ></path>
        <path
          fill="#A42B9E"
          d="M-0.686 47H71.68499999999999V58.5H-0.686z"
        ></path>
        <path
          fill="#009EE5"
          d="M-0.686 58.5H71.68499999999999V70H-0.686z"
        ></path>
      </g>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M52.075 17.585c1.084-.396 2.136.658 1.74 1.742L40.737 55.162c-.618 1.694-2.969 1.809-3.748.182l-6.575-13.722a1.358 1.358 0 00-.638-.638l-13.718-6.57c-1.627-.78-1.513-3.135.181-3.753l35.836-13.075z"
        clipRule="evenodd"
      ></path>
    </svg>
    );
}