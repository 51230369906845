import React from "react";

function IconCommandF() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="91"
      height="44"
      fill="none"
      viewBox="0 0 91 44"
    >
      <rect
        width="43"
        height="43"
        x="47.5"
        y="0.5"
        fill="#2B0510"
        fillOpacity="0.2"
        rx="4"
        style={{ mixBlendMode: "hard-light" }}
      ></rect>
      <path
        fill="url(#paint0_linear_488_4546)"
        d="M65.92 26.932h1.532v-4.129h4.238v-1.292h-4.238v-3.124h4.628v-1.32h-6.16v9.865z"
      ></path>
      <rect
        width="43"
        height="43"
        x="47.5"
        y="0.5"
        stroke="url(#paint1_linear_488_4546)"
        rx="4"
      ></rect>
      <rect
        width="43"
        height="43"
        x="0.5"
        y="0.5"
        fill="#2B0510"
        fillOpacity="0.2"
        rx="4"
        style={{ mixBlendMode: "hard-light" }}
      ></rect>
      <path
        fill="url(#paint2_linear_488_4546)"
        d="M19.847 20.923v2.154h-.937c-1.114.006-2.023.881-2.023 2.003 0 1.12.902 2.03 2.023 2.03a2.03 2.03 0 002.024-2.03v-.923h2.126v.923c0 1.12.909 2.03 2.023 2.03a2.03 2.03 0 002.03-2.03c0-1.121-.909-1.996-2.03-2.003h-.93v-2.154h.93c1.121 0 2.03-.881 2.03-2.003a2.03 2.03 0 00-2.03-2.03 2.03 2.03 0 00-2.023 2.03v.93h-2.126v-.93a2.03 2.03 0 00-2.024-2.03c-1.12 0-2.023.91-2.023 2.03 0 1.121.909 2.003 2.023 2.003h.937zm-.923-1.06a.945.945 0 01-.944-.943c0-.512.424-.936.93-.936a.95.95 0 01.937.95v.93h-.923zm6.145 0h-.916v-.929c0-.526.424-.95.93-.95a.94.94 0 01-.014 1.88zm-4.135 3.227v-2.174h2.126v2.174h-2.126zm-2.01 1.046h.923v.923a.95.95 0 01-.937.95.937.937 0 01-.93-.936c0-.513.424-.937.944-.937zm6.145 0a.94.94 0 01.944.937c0 .52-.417.936-.93.936a.944.944 0 01-.93-.95v-.923h.916z"
      ></path>
      <rect
        width="43"
        height="43"
        x="0.5"
        y="0.5"
        stroke="url(#paint3_linear_488_4546)"
        rx="4"
      ></rect>
      <defs>
        <linearGradient
          id="paint0_linear_488_4546"
          x1="69"
          x2="69"
          y1="17.068"
          y2="26.932"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.031" stopColor="#D2DAE4"></stop>
          <stop offset="1" stopColor="#8EBDF9"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_488_4546"
          x1="69"
          x2="69"
          y1="1"
          y2="43"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DB3173"></stop>
          <stop offset="0.698" stopColor="#F16F81" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_488_4546"
          x1="22"
          x2="22"
          y1="16.89"
          y2="27.11"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.031" stopColor="#D2DAE4"></stop>
          <stop offset="1" stopColor="#8EBDF9"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_488_4546"
          x1="22"
          x2="22"
          y1="1"
          y2="43"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DB3173"></stop>
          <stop offset="0.698" stopColor="#F16F81" stopOpacity="0"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default IconCommandF;
