import { themeObject } from "../../../../../global-style";
import { SamAndBoBudget } from "./budget-graphs";
import { SamAndBoRecurring } from "./recurring-graphs";
import { FidelityIcon, WealthfrontIcon } from "./brokerage-logos";

const SamAndBo = {
  "integrations": {
    "amazon": [{
        "category": { "name": "Doge", "color": "#A01F8A", "emoji": "🐶" },
        "quantity": 1,
        "product": "Purina Pro Plan with Probiotics Shredded Blend High Protein",
        "price": 17.84
      }, 
      {
        "category": { "name": "Shops", "color": "#2E6B1F", "emoji": "🛍" },
        "quantity": 2,
        "product": "USB C Female to USB Male Adapter 2 Pack",
        "price": 24.98
      }],
    "venmo": [{}]
  },
  "amazon": {
    "purchases": [{
      "category": { "name": "Doge", "color": "#A01F8A", "emoji": "🐶" },
      "quantity": 1,
      "product": "Purina Pro Plan with Probiotics Shredded Blend High Protein",
      "price": 17.84
    }, 
    {
      "category": { "name": "Shops", "color": "#2E6B1F", "emoji": "🛍" },
      "quantity": 2,
      "product": "USB C Female to USB Male Adapter 2 Pack",
      "price": 24.98
    }]
  },
  "rules": [{
    "rule": "Wine-o",
    "category": { "name": "Oops", "color": themeObject.color.categoryBlue, "emoji": "🙈" }
  },
  {
    "rule": "Macosa",
    "category": { "name": "Date night", "color": themeObject.color.categoryViolet, "emoji": "🍷" }
  },
  {
    "rule": "Con Ed",
    "category": { "name": "Utilities", "color": themeObject.color.categoryOrange, "emoji": "💡" }
  }
  ],
  "budget": {
    "left": 566,
    "total": "8,230",
    "graph": SamAndBoBudget,
    "budgeting_disabled": false,
  },
  "spending": {
    "status": "12.65",
    "over": false,
    "budgeting_disabled": false,
    "categories": [
      { "name": "Honda", "color": themeObject.color.categoryRed, "emoji": "🚙", "performance": 97 },
      { "name": "Doge", "color": themeObject.color.categoryBrown, "emoji": "🐶", "performance": 67 },
      { "name": "Utilities", "color": themeObject.color.categoryOrange, "emoji": "💡", "performance": 50 },
      { "name": "Vacation", "color": themeObject.color.categoryRuby, "emoji": "⛱", "performance": 50 },
      { "name": "Work expenses", "color": themeObject.color.categoryLemon, "emoji": "💼", "performance": 48 },
      { "name": "Date night", "color": themeObject.color.categoryViolet, "emoji": "🍷", "performance": 42 },
      { "name": "Oops", "color": themeObject.color.categoryBlue, "emoji": "🙈", "performance": 33 },
      { "name": "Ford", "color": themeObject.color.categoryLemon, "emoji": "🚘", "performance": 20 },
      { "name": "Home", "color": themeObject.color.categoryViolet, "emoji": "🪴", "performance": 16 },
      { "name": "Groceries", "color": themeObject.color.categoryGreen, "emoji": "🍎", "performance": 16 },
      { "name": "Healthcare", "color": themeObject.color.categoryBrown, "emoji": "👕", "performance": 3 }
    ]
  },
  "investments": [
    { "name": "Fidelity", "balance": "52,996.75", "performance": 3.12, icon: FidelityIcon },
    { "name": "Wealthfront", "balance": "16,404.89", "performance": 2.54, icon: WealthfrontIcon }
    // { "name": "Wealthfront", "balance": "1,923.02", "performance": 2.54 }
  ],
  "recurrings": {
    "left": 245,
    "total": 700,
    "graph": SamAndBoRecurring,
    "schedule": [
      { "name": "Mortgage", "emoji": "🏠", "balance": "2120.53", "date": "1st", "paid": true },
      { "name": "Audible", "emoji": "🎧", "balance": "9.99", "date": "11th", "paid": false },
      { "name": "Netflix", "emoji": "📺", "balance": "15.99", "date": "5th", "paid": true },
      { "name": "Chase CC", "emoji": "🏦", "balance": "200", "date": "11th", "paid": false }
    ]
  }
}

export default SamAndBo;