import React from "react";
import Grid from "../../shared/grid";
import { H2 } from "../../shared/headings";
import Section from "../../shared/section";
import PricePanel from "./price-panel";
import DemoPanel from "./demo-panel";

const Pricing = () => {
    return (
        <Section id="pricing">
            <Grid>
                <H2>Honest and thoughtful pricing</H2>
                <DemoPanel/>
                <PricePanel/>
            </Grid>
        </Section>
    )
}


export default Pricing