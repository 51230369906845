import * as React from "react";

import { FeaturePanel } from "../content-elements";
import Grid from "../../../shared/grid";
import { H2 } from "../../../shared/headings";
import Section from "../../../shared/section";
import { SingleFeature } from "../content-elements";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

const Intelligence = () => {
  return (
    <Section id="intelligence">
      <Grid>
        <H2 dense>
          Now with AI-Powered <ConditionalLineBreak>spending categorization</ConditionalLineBreak>
        </H2>
        <SingleFeature>
          <FeaturePanel
            description="A personalized categorization engine that gets smarter every time you review transactions."
            title="Copilot Intelligence"
            link="/intelligence"
            outbound={true}
            linkTitle="Learn more"
            imageStyle={{ marginBottom: 48, height: "fit-content" }}
          >
            <StaticImage
              alt=""
              src="./images/intelligence-illustration.png"
              width={440}
              quality={100}
              placeholder="none"
            />
          </FeaturePanel>
        </SingleFeature>
      </Grid>
    </Section>
  );
};

const ConditionalLineBreak = styled.span`
  display: inline;
  @media screen and (min-width: 1106px) {
    display: block;
  }
`;

export default Intelligence;
