import React from "react";

function IconCredit() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_433_3386)">
        <path
          fill="#A2AB35"
          fillRule="evenodd"
          d="M2 6a1 1 0 00-1 1v1.75h22V7a1 1 0 00-1-1H2zm21 5.25H1V18a1 1 0 001 1h20a1 1 0 001-1v-6.75z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_433_3386">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconCredit;
