import React from "react";

function IconFraud() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_433_3466)">
        <path
          fill="#EC682C"
          d="M18.63 4.554c-.716 0-1.296.525-1.296 1.173v5.554h-.742V3.967c0-.648-.58-1.172-1.296-1.172-.716 0-1.295.524-1.295 1.172v7.314h-.742V2.594c0-.647-.58-1.172-1.296-1.172-.716 0-1.296.525-1.296 1.172v8.687H9.85V3.967c0-.648-.581-1.172-1.297-1.172-.715 0-1.295.524-1.295 1.172v10.495l-1.789-2.353c-.39-.543-1.192-.698-1.792-.345-.6.352-.772 1.078-.382 1.622l3.652 5.717c1.075 1.574 3.452 2.985 5.921 2.985 3.943 0 6.889-2.482 6.889-5.55l.17-5.256V5.727c0-.648-.581-1.173-1.297-1.173z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_433_3466">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconFraud;
