import React from "react";
import Grid from "../../shared/grid";
import { H2 } from "../../shared/headings";
import Section from "../../shared/section";
import styled from "styled-components";
import { DoubleFeature, FeaturePanel } from "../features/content-elements";
import { BulkActions, CashFlow, ColorModes, Widgets, QuickLauncher, FastSearch, FaceID, AppleCard } from "./panels";
import { StaticImage } from "gatsby-plugin-image";

const FirstClassExperience = () => {
    return (
        <Section>
            <Grid>
                <H2>Copilot provides a <ConditionalLineBreak>best-in-class experience</ConditionalLineBreak></H2>
            </Grid>
            <QualityGridContainer>
                <QualityGrid>
                    <BulkActions/>
                    <CashFlow/>
                    <ColorModes/>
                    <Widgets/>
                    <QuickLauncher/>
                    <FastSearch/>
                    <FaceID/>
                    <AppleCard/>
                </QualityGrid>
            </QualityGridContainer>
            <Grid style={{paddingTop: 80}}>
                <DoubleFeature>
                    <FeaturePanel 
                        hideBg 
                        title="Native applications" 
                        description="Built with Apple frameworks for the best performance on Mac, iPhone, and iPad"
                        link="/download"
                        linkTitle="Download"
                        imageStyle={{ marginLeft: 20, marginBottom: 0, height: "fit-content" }}
                    >
                        <StaticImage
                            src="./images/native-apps.png"
                            alt=""
                            width={440}
                            placeholder="none"
                            quality={100}
                        />
                    </FeaturePanel>
                    <FeaturePanel 
                        hideBg
                        title="Privacy & security"
                        description="We treat your personal and financial data like we’d want ours to be treated"
                        link="/privacy-and-security"
                        linkTitle="Learn more"
                        imageStyle={{ marginBottom: 0, height: "fit-content" }}
                    >
                        <StaticImage
                            src="./images/priv-sec.png"
                            alt=""
                            width={440}
                            placeholder="none"
                            quality={100}
                        />
                    </FeaturePanel>
                </DoubleFeature>
            </Grid>
        </Section>
    )
}

const ConditionalLineBreak = styled.span`
display: inline-block;
${props => props.theme.device.laptop} {
    display: block;
}
`
const QualityGrid = styled(Grid)`
    gap: 10px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(10, 272px);
    border-radius: 10px;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
    ${props => props.theme.device.tablet} {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(6, 293px);
        gap: 10px;
    }
    ${props => props.theme.device.laptop} {
        gap: 16px;
    }
    ${props => props.theme.device.laptopLg} {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, 293px);
        gap: 32px;
    }
`
const QualityGridContainer = styled.div`
    overflow: hidden;
    margin: 0 auto;
    max-width: 351px;
    ${props => props.theme.device.tablet} {
        max-width: 688px;
    }
    ${props => props.theme.device.laptopLg} {
        // max-width: 1600px;
        max-width: 1336px;
    }
`
export default FirstClassExperience