import React from "react";
import styled from "styled-components";
import Panel from "../../shared/panel";
import { StaticImage } from "gatsby-plugin-image";
import ButtonElement from "../../shared/button-element";
import { 
    SLIDE_HEIGHT,
    SLIDE_HEIGHT_TABLET,
    SLIDE_HEIGHT_LAPTOP,
    SLIDE_HEIGHT_LAPTOP_LARGE
} from "./constants";

const DemoPanel = () => {
    return (
        <StyledPanel title="Test drive for free" fullHeight>
            <Info>
                Take Copilot for a spin before you connect a single account. If you like what you see, you can start a free trial.
            </Info>
            <DemoContent>
                <CallToAction>
                    <ButtonElement to="/download">Download</ButtonElement>
                </CallToAction>
                <DemoImage>
                    <StaticImage
                        width={405} src="./demo-mode.png"
                        alt="Copilot's demo mode interface"
                        placeholder="blurred"
                        quality={100}
                    />
                </DemoImage>
            </DemoContent>
        </StyledPanel>
    )
}

const CallToAction = styled.div`
    display: flex;
    justify-content: center;
    & a {
        background-color: ${props => props.theme.color.textPrimary};
    }
`
const StyledPanel = styled(Panel)`
    // background-color: ${props => props.theme.color.blue};
    // background-color: #051426;
    background: linear-gradient(90deg, #991D41 43.77%, #5D0FAE 150.08%);
    background-image: linear-gradient(to right, color(display-p3 0.605 0.082 0.309), color(display-p3 0.354 0 0.708));
    height: ${SLIDE_HEIGHT}px;
    ${props => props.theme.device.tablet} {
        height: ${SLIDE_HEIGHT_TABLET}px;
    }
    ${props => props.theme.device.laptop} {
        height: ${SLIDE_HEIGHT_LAPTOP}px;
    }
    ${props => props.theme.device.laptopLg} {
        height: ${SLIDE_HEIGHT_LAPTOP_LARGE}px;
    }
`

const Info = styled.div`
    font-size: ${props => props.theme.fontSize.regular};
    line-height: ${props => props.theme.lineHeight.regular};
    max-width: 410px;
    margin: 0 auto;
    color: ${props => props.theme.color.textPrimary};
    margin-bottom: ${props => props.theme.spacing[7]};
    padding-top: ${props => props.theme.spacing[7]};
    text-align: center;
    font-weight: 600;
    ${props => props.theme.device.laptop} {
        font-size: ${props => props.theme.fontSize.regular2};
        line-height: 1.5;
        padding-top: ${props => props.theme.spacing[8]};
    }
`
const DemoImage = styled.div`
    margin-top: 56px;
    margin-bottom: -16px;
`
const DemoContent = styled.div`
    margin: 0 auto;
    text-align: center;
`
export default DemoPanel