import React from "react";

function IconSplitRecurring() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="36"
      fill="none"
      viewBox="0 0 37 36"
    >
      <g clipPath="url(#clip0_359_2354)">
        <g filter="url(#filter0_i_359_2354)">
          <path
            fill="#6AA7F8"
            d="M32.502 15h-28.5a.75.75 0 01-.75-.75V2.714a.75.75 0 011.085-.671l2.33 1.164a.75.75 0 00.67 0l3.08-1.54a.75.75 0 01.67 0l3.08 1.54a.75.75 0 00.67 0l3.08-1.54a.75.75 0 01.67 0l3.08 1.54a.75.75 0 00.67 0l3.08-1.54a.75.75 0 01.67 0l3.08 1.54a.75.75 0 00.67 0l2.33-1.164a.75.75 0 011.085.67V14.25a.75.75 0 01-.75.75z"
          ></path>
        </g>
        <path
          fill="#6AA7F8"
          d="M4.002 21h28.5a.75.75 0 01.75.75v11.536a.75.75 0 01-1.086.671l-2.329-1.164a.75.75 0 00-.67 0l-3.08 1.54a.75.75 0 01-.67 0l-3.08-1.54a.75.75 0 00-.67 0l-3.08 1.54a.75.75 0 01-.67 0l-3.08-1.54a.75.75 0 00-.67 0l-3.08 1.54a.75.75 0 01-.67 0l-3.08-1.54a.75.75 0 00-.67 0l-2.33 1.164a.75.75 0 01-1.085-.67V21.75a.75.75 0 01.75-.75z"
        ></path>
        <g filter="url(#filter1_i_359_2354)">
          <path
            stroke="#6AA7F8"
            strokeLinecap="round"
            strokeWidth="2"
            d="M2.002 18h32.5"
          ></path>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i_359_2354"
          width="30"
          height="13.412"
          x="3.252"
          y="1.589"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_2354"></feBlend>
        </filter>
        <filter
          id="filter1_i_359_2354"
          width="34.5"
          height="2"
          x="1.002"
          y="17"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="0.5"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_2354"></feBlend>
        </filter>
        <clipPath id="clip0_359_2354">
          <path
            fill="#fff"
            d="M0 0H36V36H0z"
            transform="translate(.252)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconSplitRecurring;
