import create from "zustand";
import SamAndBo from "./personas/sam-and-bo";
// import Lucas from "./personas/lucas";
// import Emily from "./personas/emily";
// import Mateo from "./personas/mateo";

const useStore = create((set) => ({
    persona: SamAndBo,
    setPersona: (persona) => set((state) => ({ persona: persona })),
}))

export default useStore