import React, { useState, useRef, useLayoutEffect } from 'react'
import { 
  motion, 
  useScroll, 
  useTransform, 
  useSpring, 
  useReducedMotion 
} from 'framer-motion'
import { isMobile } from 'react-device-detect';


const Parallax = ({ children, offset = 50, clamp = true}) => {
  const prefersReducedMotion = useReducedMotion()
  const [elementTop, setElementTop] = useState(0)
  const [clientHeight, setClientHeight] = useState(0)
  const ref = useRef(null)

  const { scrollY } = useScroll()

  const initial = elementTop - clientHeight
  const final = elementTop + offset

  const yRange = useTransform(scrollY, [initial, final], [offset, -offset], {clamp: clamp})
  const y = useSpring(yRange, { stiffness: 400, damping: 90 })

  useLayoutEffect(() => {
    const element = ref.current
    const onResize = () => {
      if(!prefersReducedMotion) {
        setElementTop(element && element.getBoundingClientRect().top + window.scrollY || window.pageYOffset)
        setClientHeight(window.innerHeight)
      }
    }
    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [ref, prefersReducedMotion])

  // Don't parallax if the user has "reduced motion" enabled or if we are on a mobile device
  if (prefersReducedMotion || isMobile) {
    return <>{children}</>
  }

  return (
    <motion.div ref={ref} style={{ y }}>
      {children}
    </motion.div>
  )
}

export default Parallax