import React from "react";

function IconFees() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill="#C128F6" clipPath="url(#clip0_433_3433)">
        <path d="M24 10.543v1.44H0v-1.44h1.795c.2-2.215.999-8.611 3.718-10.176.592-.343 1.564-.62 2.815.005.166.084 4.77 2.66 6.47 3.613a.717.717 0 10.697-1.256L13.39 1.565A82.356 82.356 0 0115.53.372c1.25-.624 2.223-.348 2.816-.005 2.719 1.565 3.518 7.96 3.717 10.176H24zM17.932 13.702a4.708 4.708 0 00-4.536 3.478 3.34 3.34 0 00-2.877.021 4.684 4.684 0 10.139 1.642 1.892 1.892 0 012.592-.053 4.7 4.7 0 104.682-5.088z"></path>
      </g>
      <defs>
        <clipPath id="clip0_433_3433">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconFees;
