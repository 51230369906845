import React from "react";
import { motion } from "framer-motion";

const transition = { duration: 1.7, ease: "easeInOut" };

export const SamAndBoRecurring = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      fill="none"
      viewBox="0 0 70 70"
    >
      <circle
        cx="35.167"
        cy="35.167"
        r="22.51"
        stroke="#02070D"
        strokeWidth="17.315"
        style={{ mixBlendMode: "luminosity" }}
      ></circle>
      <motion.path
        stroke="#6AA7F8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8"
        d="M35.165 12.79c12.357 0 22.375 10.018 22.375 22.375S47.522 57.54 35.165 57.54 12.79 47.522 12.79 35.165c0-3.478.794-6.771 2.21-9.708"
        initial={{ pathLength: 0 }}
        animate={{ pathLength: 1 }}
        transition={transition}
      ></motion.path>
    </svg>
  );
}

export const LucasRecurring = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      fill="none"
      viewBox="0 0 70 70"
    >
      <circle
        cx="35.167"
        cy="35.167"
        r="22.51"
        stroke="#02070D"
        strokeWidth="17.315"
        style={{ mixBlendMode: "luminosity" }}
      ></circle>
      <motion.path
        stroke="#6AA7F8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8"
        d="M35.165 12.79c12.357 0 22.375 10.018 22.375 22.375S47.522 57.54 35.165 57.54 12.79 47.522 12.79 35.165c0-8.291 4.51-15.53 11.21-19.395"
        initial={{ pathLength: 0 }}
        animate={{ pathLength: 1 }}
        transition={transition}
      ></motion.path>
    </svg>
  )
}

export const EmilyRecurring = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      fill="none"
      viewBox="0 0 70 70"
    >
      <circle
        cx="35.167"
        cy="35.167"
        r="22.51"
        stroke="#02070D"
        strokeWidth="17.315"
        style={{ mixBlendMode: "luminosity" }}
      ></circle>
      <motion.path
        stroke="#6AA7F8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8"
        d="M35.165 12.79c12.357 0 22.375 10.018 22.375 22.375S47.522 57.54 35.165 57.54 12.79 47.522 12.79 35.165c0-12.325 9.964-22.322 22.276-22.375"
        initial={{ pathLength: 0 }}
        animate={{ pathLength: 1 }}
        transition={transition}
      ></motion.path>
    </svg>
  )
}

export const MateoRecurring = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      fill="none"
      viewBox="0 0 70 70"
    >
      <circle
        cx="35.167"
        cy="35.167"
        r="22.51"
        stroke="#02070D"
        strokeWidth="17.315"
        style={{ mixBlendMode: "luminosity" }}
      ></circle>
      <motion.path
        stroke="#6AA7F8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="8"
        d="M35.165 12.79c12.357 0 22.375 10.018 22.375 22.375S47.522 57.54 35.165 57.54 12.79 47.522 12.79 35.165c0-1.15.087-2.278.254-3.381"
        initial={{ pathLength: 0 }}
        animate={{ pathLength: 1 }}
        transition={transition}
      ></motion.path>
    </svg>
  )
}

