import React from "react";
import styled from "styled-components";

// usage

// <TwitterReview
//     avatar="https://pbs.twimg.com/profile_images/1260329587004727296/AGBpyYrO_400x400.jpg"
//     author="Adhithya"
//     username="@adhidesign"
//     verified={false}
//     message={<React.Fragment>Easily <TwLink>@copilotmoney</TwLink> is the best budgeting app I’ve ever used! 💯</React.Fragment>}
// />
// <AppleReview
//     rating={5}
//     attributionString="azdbackfan, 12/12/2020"
//     title="Love this app"
//     message={<React.Fragment>You guys have captured the essence of what a great personal financial app should be! It’s beautifully designed and easy to navigate. I’ve tried several other financial apps but none quite as effortless as this one... great job. Just finishing up my 1-month trial and glad to sign up for the 1-year subscription.</React.Fragment>}
// />
// const TwLink = styled.span`
//     color: #50BCFF;
// `

const Review = ({
    type,
    author,
    username,
    children,
    message,
    verified=false,
    title,
    attributionString="",
    rating=5
}) => {
    const isTwitter = type === "twitter";
    return (
        <Container>
            {isTwitter 
                ? <TwitterHeader author={author} username={username} children={children} verified={verified} />
                : <AppleHeader attributionString={attributionString} rating={rating} />
            }
            <Body>
                {!isTwitter && (<Title>{title}</Title>)}
                {message}
            </Body>
        </Container>
    )
}

const TwitterHeader = ({author, username, verified, children}) => {
    return (
        <Header>
            <Attribution type="twitter">
                <Profile>{children}</Profile>
                <Author>
                    <Name>{author}{verified && <BlueCheck/>}</Name>
                    <Username>{username}</Username>
                </Author>
            </Attribution>
            <Brand><TwitterLogo/></Brand>
        </Header>
    )
}

const AppleHeader = ({attributionString, rating}) => {
    return (
        <Header>
            <Attribution type="apple">
                <Rating>
                    {[...Array(rating)].map((star,i) => {
                        return <Star key={`star-${i}`} />
                    })}
                </Rating>
                <AttributionString>
                    {attributionString}
                </AttributionString>
            </Attribution>
            <Brand><AppleLogo/></Brand>
        </Header>
    )
}


export const TwitterReview = ({
    author,
    username,
    message,
    verified=false,
    children
}) => (
    <Review
        type="twitter"
        author={author}
        username={username}
        children={children}
        message={message}
        verified={verified} 
    />
)

export const AppleReview = ({
    rating,
    attributionString,
    title,
    message
}) => (
    <Review
        type="apple"
        rating={rating}
        attributionString={attributionString}
        title={title}
        message={message}
    />
)

const Container = styled.div`
    background-color: rgba(93,118,150, 0.2);
    border-radius: 14px;
    padding: 12px 13px 16px 13px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #B3C7DE;
`
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
`

const Attribution = styled.div`
    display: ${props => props.type === 'twitter' ? 'flex' : 'block'};
    align-items: center;
`
const Body = styled.div`
    line-height: 1.4;
`
const Brand = styled.div`
    & svg {
        width: 1.2rem;
    }
`

// twitter 
const Profile = styled.div`
    background-image: url(${props => props.url});
    background-size: 100%;
    overflow: hidden;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 1.1rem;
    & .gatsby-image-wrapper [data-main-image] {
        border-radius: 1.1rem;
    }
`
const Author = styled.div`
    color: #D2E1F2;
    padding-left: 0.5rem;
`
const Name = styled.div`
    font-weight: 600;
`
const Username = styled.div`
    color: #7895B5;
`

// apple
const Rating = styled.div`
    & svg {
        margin-right: 1px;
    }
`
const Title = styled.p`
    margin: 0 0 17px;
    color: #D2DAE4;
    display: block;
`
const AttributionString = styled.div`
    color: #7895B5;
`


const TwitterLogo = (props) => {
    const fill = props.fill || "#1DA1F2";
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M7.54752 21.7508C16.6042 21.7508 21.5578 14.2474 21.5578 7.74052C21.5578 7.5274 21.5578 7.31524 21.5434 7.10404C22.507 6.407 23.3389 5.54392 24 4.55524C23.1014 4.95364 22.148 5.2148 21.1718 5.32996C22.1998 4.71465 22.9692 3.74674 23.3366 2.60644C22.3701 3.18005 21.3126 3.58427 20.2099 3.80164C19.4675 3.01222 18.4856 2.48948 17.4162 2.31432C16.3468 2.13917 15.2494 2.32135 14.294 2.83269C13.3385 3.34403 12.5782 4.15601 12.1307 5.14299C11.6833 6.12996 11.5735 7.23691 11.8186 8.29252C9.8609 8.19432 7.94576 7.68555 6.19745 6.79924C4.44915 5.91294 2.90676 4.6689 1.6704 3.14788C1.04073 4.23188 0.847872 5.51511 1.1311 6.7363C1.41433 7.9575 2.15234 9.02483 3.19488 9.721C2.41123 9.69804 1.64465 9.48663 0.96 9.10468V9.16708C0.960311 10.3039 1.35385 11.4057 2.07387 12.2854C2.79389 13.1652 3.79606 13.7689 4.9104 13.994C4.18548 14.1917 3.42487 14.2206 2.68704 14.0784C3.00181 15.0568 3.61443 15.9123 4.43924 16.5254C5.26405 17.1385 6.25983 17.4785 7.28736 17.498C6.26644 18.3004 5.09731 18.8938 3.84687 19.244C2.59643 19.5942 1.28921 19.6944 0 19.5389C2.25183 20.9839 4.87192 21.7504 7.54752 21.7469" fill="inherit"/>
        </svg>
    )
}

const AppleLogo = (props) => {
    const fill = props.fill || "#D2E1F2";
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M15.2647 4.53296C15.9467 3.70562 16.4275 2.57642 16.4275 1.43603C16.4275 1.27951 16.4163 1.12298 16.3939 1C15.2759 1.04472 13.9343 1.7379 13.1405 2.67704C12.5032 3.39257 11.9218 4.53296 11.9218 5.67335C11.9218 5.85223 11.9554 6.01993 11.9666 6.07584C12.0336 6.08702 12.1454 6.10938 12.2684 6.10938C13.2635 6.10938 14.5157 5.43856 15.2647 4.53296ZM16.0474 6.34416C14.3815 6.34416 13.0175 7.36157 12.1454 7.36157C11.2175 7.36157 10.01 6.41124 8.55659 6.41124C5.79506 6.41124 3 8.69202 3 12.9852C3 15.6685 4.02858 18.4971 5.31431 20.3195C6.40998 21.8624 7.37148 23.1257 8.75783 23.1257C10.1218 23.1257 10.7256 22.2201 12.425 22.2201C14.1467 22.2201 14.538 23.1034 16.0474 23.1034C17.5455 23.1034 18.5406 21.7282 19.4909 20.3754C20.5418 18.8213 20.989 17.312 21.0002 17.2337C20.9108 17.2114 18.0486 16.0374 18.0486 12.7616C18.0486 9.92184 20.2959 8.6473 20.43 8.54667C18.943 6.41124 16.6735 6.34416 16.0474 6.34416Z" fill="inherit"/>
        </svg>
    )
}

const BlueCheck = () => {
    return (
        <></>
    )
}

const Star = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 15 15">
            <path fill="#FED774" d="M7.5 0.25 L9.375 6 h5.625 L10.375 9.25 L12.25 14.875 L7.5 11.375 L2.75 14.875 L4.625 9.25 L0 6 h5.625 Z" />
        </svg>
    )
}

export default Review