import React from "react";

function IconIntegrations() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="36"
      fill="none"
      viewBox="0 0 37 36"
    >
      <g
        fill="#6AA7F8"
        clipPath="url(#clip0_359_1767)"
        filter="url(#filter0_i_359_1767)"
      >
        <path d="M2.502 31.875V21a1.5 1.5 0 011.5-1.5h10.875a1.5 1.5 0 011.5 1.5v10.875a1.5 1.5 0 01-1.5 1.5H4.002a1.5 1.5 0 01-1.5-1.5zM20.127 14.625V3.75a1.5 1.5 0 011.5-1.5h10.875a1.5 1.5 0 011.5 1.5v10.875a1.5 1.5 0 01-1.5 1.5H21.627a1.5 1.5 0 01-1.5-1.5zM34.683 26.617l-1.987 5.165a.5.5 0 01-.287.287l-5.165 1.987a.5.5 0 01-.359 0l-5.165-1.987a.5.5 0 01-.287-.287l-1.987-5.165a.5.5 0 010-.359l1.987-5.165a.5.5 0 01.287-.287l5.165-1.987a.5.5 0 01.359 0l5.165 1.987a.5.5 0 01.287.287l1.987 5.165a.5.5 0 010 .359zM2.837 13.954L8.285 3.058c.553-1.105 2.13-1.105 2.684 0l5.447 10.896a1.5 1.5 0 01-1.341 2.171H4.179a1.5 1.5 0 01-1.342-2.17z"></path>
      </g>
      <defs>
        <filter
          id="filter0_i_359_1767"
          width="36"
          height="36"
          x="0.252"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_1767"></feBlend>
        </filter>
        <clipPath id="clip0_359_1767">
          <path
            fill="#fff"
            d="M0 0H36V36H0z"
            transform="translate(.252)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconIntegrations;
