import React, { useRef } from 'react'
import { 
  motion, 
  useScroll, 
  useTransform, 
  useSpring, 
  useReducedMotion 
} from 'framer-motion'
import { isMobile } from 'react-device-detect';


const ScrollOffset = ({ children, scrollStart = 0, scrollEnd = 2000, offsetStart = 0, offsetEnd = 200, clamp = true}) => {
  const prefersReducedMotion = useReducedMotion()
  const ref = useRef(null)

  const { scrollY } = useScroll()

  const yRange = useTransform(scrollY, [scrollStart, scrollEnd], [offsetStart, offsetEnd], {clamp: clamp});
  const y = useSpring(yRange, { stiffness: 400, damping: 90 })

  // Don't offset scroll if the user has "reduced motion" enabled or if we are on a mobile device
  if (prefersReducedMotion || isMobile) {
    return <>{children}</>
  }

  return (
    <motion.div ref={ref} style={{ y }}>
      {children}
    </motion.div>
  )
}

export default ScrollOffset