import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { H5 } from "../../shared/headings";
import { 
    SLIDE_HEIGHT,
    SLIDE_HEIGHT_TABLET,
    SLIDE_HEIGHT_LAPTOP,
    SLIDE_HEIGHT_LAPTOP_LARGE,
    MONTHLY_PRICE_BILLED_YEARLY,
    ANNUAL_FEE,
    MONTHLY_PRICE
} from "./constants";
import cssOutlineMixin from "../../shared/css-outline-mixin";

const PricePanel = () => {
    const [isMonthly, setIsMonthly] = useState(false);
    const [moreInfoVisible, setMoreInfoVisible] = useState(false);
    const togglePrice = () => setIsMonthly(!isMonthly);
    return (
        <StyledPanel>
            <Slides>
                {moreInfoVisible ? (
                    <Slide>
                        <Header>
                            <PanelTitle></PanelTitle>
                            <CloseButton 
                                onClick={() => { setMoreInfoVisible(false) }}
                                whileHover={{fill: '#fff', scale: 1.2, color: "#fff", transition: {type: "spring", stiffness: 700, damping: 30}}}
                                whileTap={{scale: 1.02}}    
                                tabIndex={!moreInfoVisible ? -1 : 0}
                            >
                                <CloseIcon/>
                            </CloseButton>
                        </Header>
                        <PricingContent>
                            <PriceExplanation>
                                {/* <IllustrationPlaceholder>Illustration?</IllustrationPlaceholder> */}
                                    <H5>We’re in this for the long haul. We want to continue building and improving on Copilot for years to come, and subscription pricing makes that possible.</H5>
                                    <p>We believe charging a fair price for a well-made product is among the most honest ways to run a sustainable business. We get to do the work we love, and you get the comfort of knowing that we’ll never sell your data or promote other companies’ products just to make a buck.</p>
                            </PriceExplanation>
                        </PricingContent>
                    </Slide>
                ) : (
                    <Slide>
                        <Header>
                            <H5>Pricing</H5>
                        </Header>
                        <PricingContent>
                            <FlexTop>
                                <Price isMonthly={isMonthly}><Usd/>{isMonthly ? MONTHLY_PRICE : MONTHLY_PRICE_BILLED_YEARLY}</Price>
                                <PriceDetails>
                                    {!isMonthly && <UsdBright/>}
                                    {!isMonthly && `${ANNUAL_FEE} billed yearly`}
                                </PriceDetails>
                                <Toggle>
                                    <Label>Pay yearly</Label>
                                    <Switch data-is-monthly={isMonthly} onClick={togglePrice}>
                                        <Handle 
                                            layout
                                            transition={{type: "spring", stiffness: 700, damping: 30}} 
                                        />
                                    </Switch>
                                    <Label>monthly</Label>
                                </Toggle>
                                <Info>
                                    No ads, hidden fees, or shenanigans. Your data stays private, and we get to focus on building the best product for you.
                                </Info>
                            </FlexTop>
                            <FlexBottom>
                                <MoreInfo
                                    onClick={() => setMoreInfoVisible(true)}
                                    whileHover={{scale: 1.05, color: "#fff", transition: {type: "spring", stiffness: 700, damping: 30}}}
                                    whileTap={{scale: 1.02}}
                                >
                                    Why isn't Copilot free?
                                </MoreInfo>
                            </FlexBottom>
                        </PricingContent>
                    </Slide>
                )}
            </Slides>
        </StyledPanel>
    )
}

const StyledPanel = styled.div`
    border-radius: 10px;
    // background-color: ${props => props.theme.color.uiCardGround};
    background-color: #5D0FAE;
    background-color: color(display-p3 0.286 0 0.708);
    // background-color: ${props => props.theme.color.blue};
    padding: 20px;
    grid-column: col-start / span 12;
    overflow: hidden;
    padding-bottom: 18px;
    ${props => props.theme.device.tablet} {
        grid-column: span 6;
    }
    height: ${SLIDE_HEIGHT}px;
    ${props => props.theme.device.tablet} {
        height: ${SLIDE_HEIGHT_TABLET}px;
    }
    ${props => props.theme.device.laptop} {
        height: ${SLIDE_HEIGHT_LAPTOP}px;
    }
    ${props => props.theme.device.laptopLg} {
        height: ${SLIDE_HEIGHT_LAPTOP_LARGE}px;
    }
    ${cssOutlineMixin}
`
const Slide = styled.div`
    height: ${SLIDE_HEIGHT}px;
    ${props => props.theme.device.tablet} {
        height: ${SLIDE_HEIGHT_TABLET}px;
    }
    ${props => props.theme.device.laptop} {
        height: ${SLIDE_HEIGHT_LAPTOP}px;
    }
    ${props => props.theme.device.laptopLg} {
        height: ${SLIDE_HEIGHT_LAPTOP_LARGE}px;
    }
`
const Slides = styled.div`
`
const Header = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`
const PanelTitle = styled(H5)`
`
const CloseButton = styled(motion.button)`
    color: #6AA7F8;
    border: none;
    background: transparent;
    border-radius: 23px;
    cursor: pointer;
    &:focus {
        outline: none;
    }
    &:focus-visible {
        box-shadow: 0 0 0 2pt ${props => props.theme.color.textSecondary};
    }
`
const StyledCloseIcon = styled.svg`
    // fill: inherit;
    height: 32px;
    width: 32px;
`
const CloseIcon = () => {
    return (
        <StyledCloseIcon width="46" height="46" viewBox="0 0 46 46" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M23 0C10.2974 0 0 10.2975 0 23C0 35.7025 10.2974 46 23 46C35.7026 46 46 35.7025 46 23C46 10.2975 35.7026 0 23 0ZM13.8066 31.9852C13.416 31.5947 13.416 30.9615 13.8066 30.571L21.2321 23.1454L14.1619 16.0752C13.7714 15.6847 13.7714 15.0515 14.1619 14.661C14.5524 14.2705 15.1855 14.2705 15.5761 14.661L22.6464 21.7312L30.07 14.3075C30.4606 13.917 31.0937 13.917 31.4843 14.3075C31.8748 14.6981 31.8748 15.3312 31.4843 15.7218L24.0605 23.1454L31.8395 30.9244C32.2301 31.315 32.2301 31.9481 31.8395 32.3387C31.449 32.7292 30.8159 32.7292 30.4254 32.3387L22.6464 24.5597L15.2208 31.9852C14.8303 32.3757 14.1971 32.3757 13.8066 31.9852Z" fill="currentColor"/>
        </StyledCloseIcon>
    )
}

const PricingContent = styled.div`
    text-align: center;
    height: calc(100% - 56px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${props => props.theme.device.tablet} {
        height: calc(100% - 48px);
        justify-content: space-between;
    }
`
const Price = styled.div`
    font-size: ${props => props.theme.fontSize.xlarge};
    font-weight: 800;
    margin: 0 auto;
    margin-top: ${props => props.theme.spacing[4]};
    color: ${props => props.theme.color.textPrimary};
    ${props => props.theme.device.laptop} {
        margin-top: ${props => props.theme.spacing[7]};
        font-size: ${props => props.theme.fontSize.xxxlarge};
    }
    &:after {
        content: "/mo";
        font-weight: 600;
        font-size: 75%;
    }
`
const Usd = styled.span`
    &:before {
        content: "$";
        position: relative;
        top: 0.1em;
        font-weight: ${props => props.weight ? props.weight : 'inherit'};
        margin-right: 1px;
        font-size: 70%;
        vertical-align: text-top;
    }
`
const UsdBright = styled(Usd)`
    &:before {
        background-color: #FF5A95;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        opacity: 0.9;
    }
`
const PriceDetails = styled.div`
    font-size: ${props => props.theme.fontSize.medium};
    font-weight: 600;
    background: linear-gradient(270deg, #BE41FE 21.47%, #FF5A95 100%), #5D0FAE;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    
    
    min-height: 27px;
    ${props => props.theme.device.laptop} {
        min-height: 43px;
        font-size: ${props => props.theme.fontSize.large};
    }
`
const Toggle = styled.div`
    display: flex;
    align-items: center;
    margin: 32px auto 40px;
    justify-content: center;
`
const Label = styled(H5)`
    &:first-child {
        padding-right: ${props => props.theme.spacing[4]};
    }
    &:last-child {
        padding-left: ${props => props.theme.spacing[4]};
    }
`
const Switch = styled.button`
    border: none;
    width: 85px;
    height: 46px;
    background-color: ${props => props.theme.color.bg};
    display: flex;
    justify-content: flex-start;
    border-radius: 23px;
    padding: 4px;
    cursor: pointer;

    &[data-is-monthly="true"] {
        justify-content: flex-end;
    }

    &:focus {
        outline: none;
    }
    &:focus-visible {
        box-shadow: 0 0 0 2pt ${props => props.theme.color.textSecondary};
    }
`
const Handle = styled(motion.div)`
    width: 38px;
    height: 38px;
    border-radius: 19px;
    background-color: #8EBDF9;
    border-radius: 20px;
`
const FlexTop = styled.div``
const FlexBottom = styled.div``
const Info = styled.div`
    font-size: ${props => props.theme.fontSize.regular};
    line-height: ${props => props.theme.lineHeight.regular};
    font-weight: 600;
    max-width: 410px;
    margin: 0 auto;
    color: ${props => props.theme.color.textPrimary};
    ${props => props.theme.device.laptop} {
        font-size: ${props => props.theme.fontSize.regular2};
        line-height: 1.5;
    }
`
const PriceExplanation = styled(Info)`
    position: relative;
    max-width: 100%;
    padding: 16px 0;
    margin: 0 auto;
    text-align: left;
    & p {
        padding: 16px 0 0;
    }
    ${props => props.theme.device.laptop} {
        max-width: 100%;
    }
    ${props => props.theme.device.laptopLg} {
        max-width: 80%;
    }
`
const MoreInfo = styled(motion.button)`
    display: block;
    background-color: transparent;
    border: none;
    border-radius: 4px;
    font-weight: 600;
    padding: 8px;
    margin: 0 auto 32px;
    ${props => props.theme.device.laptop} {
        margin: 0 auto 40px;
    }
    ${props => props.theme.device.laptopLg} {
        margin: 0 auto 48px;
    }
    font-size: 1rem;
    color: ${props => props.theme.color.textPrimary};
    cursor: pointer;
    &:focus {
        outline: none;
    }
    &:focus-visible {
        box-shadow: 0 0 0 2pt ${props => props.theme.color.textSecondary};
    }
`

export default PricePanel