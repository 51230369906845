import React from "react";

function IconControl() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="36"
      fill="none"
      viewBox="0 0 37 36"
    >
      <g clipPath="url(#clip0_359_1785)">
        <path
          fill="#010912"
          d="M0 0H36V36H0z"
          transform="translate(.252)"
        ></path>
        <g filter="url(#filter0_i_359_1785)">
          <path
            stroke="#6AA7F8"
            strokeLinecap="round"
            strokeWidth="2"
            d="M2.502 28.875h3.75m27.75 0h-19.5"
          ></path>
        </g>
        <g filter="url(#filter1_i_359_1785)">
          <path
            stroke="#6AA7F8"
            strokeLinecap="round"
            strokeWidth="2"
            d="M2.502 18h12.375m19.125 0H22.377"
          ></path>
        </g>
        <g filter="url(#filter2_i_359_1785)">
          <path
            stroke="#6AA7F8"
            strokeLinecap="round"
            strokeWidth="2"
            d="M2.502 7.125h20.25m11.25 0h-3.75"
          ></path>
        </g>
        <g filter="url(#filter3_i_359_1785)">
          <circle cx="24.127" cy="7.125" r="4.125" fill="#6AA7F8"></circle>
        </g>
        <circle
          cx="24.127"
          cy="7.125"
          r="5.125"
          stroke="#010912"
          strokeWidth="2"
        ></circle>
        <g filter="url(#filter4_i_359_1785)">
          <circle cx="18.627" cy="18" r="4.125" fill="#6AA7F8"></circle>
        </g>
        <circle
          cx="18.627"
          cy="18"
          r="5.125"
          stroke="#010912"
          strokeWidth="2"
        ></circle>
        <g filter="url(#filter5_i_359_1785)">
          <circle cx="12.377" cy="28.875" r="4.125" fill="#6AA7F8"></circle>
        </g>
        <circle
          cx="12.377"
          cy="28.875"
          r="5.125"
          stroke="#010912"
          strokeWidth="2"
        ></circle>
      </g>
      <defs>
        <filter
          id="filter0_i_359_1785"
          width="33.5"
          height="2"
          x="1.502"
          y="27.875"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="0.5"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_1785"></feBlend>
        </filter>
        <filter
          id="filter1_i_359_1785"
          width="33.5"
          height="2"
          x="1.502"
          y="17"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="0.5"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_1785"></feBlend>
        </filter>
        <filter
          id="filter2_i_359_1785"
          width="33.5"
          height="2"
          x="1.502"
          y="6.125"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="0.5"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_1785"></feBlend>
        </filter>
        <filter
          id="filter3_i_359_1785"
          width="8.25"
          height="8.25"
          x="20.002"
          y="3"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_1785"></feBlend>
        </filter>
        <filter
          id="filter4_i_359_1785"
          width="8.25"
          height="8.25"
          x="14.502"
          y="13.875"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_1785"></feBlend>
        </filter>
        <filter
          id="filter5_i_359_1785"
          width="8.25"
          height="8.25"
          x="8.252"
          y="24.75"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_1785"></feBlend>
        </filter>
        <clipPath id="clip0_359_1785">
          <path
            fill="#fff"
            d="M0 0H36V36H0z"
            transform="translate(.252)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconControl;
