import * as React from "react";

import { graphql, useStaticQuery } from 'gatsby';

import DownloadModal from "../components/shared/download-modal";
import Features from "../components/sections/features";
import FirstClassExperience from "../components/sections/first-class-experience";
import Footer from "../components/sections/footer";
import Intro from "../components/sections/intro";
import Layout from "../components/shared/layout";
import Mission from "../components/sections/mission";
import Navbar from "../components/navbar";
import Pricing from "../components/sections/pricing";
import Reviews from "../components/sections/reviews";
import Seo from "../components/shared/seo";
import queryString from "query-string";

const IndexPage = ({ isPricingPage, location }) => {

  let order;
  const data = useStaticQuery(query);

  // Default order of sections
  const defaultOrder = [
    <Intro/>,                     // 0
    <Reviews/>,                   // 1
    <FirstClassExperience/>,      // 2
    <Features/>,                  // 3
    <Pricing/>,                   // 4
    <Mission teamPhotos={data}/>  // 5
  ]

  // Pricing will always be first when isPricingPage = true
  const defaultPricingOrder = [
    <Intro/>,                     // 0
    <Reviews/>,                   // 1
    <FirstClassExperience/>,      // 3
    <Features/>,                  // 4
    <Mission teamPhotos={data}/>  // 5
  ]

  // CUSTOM SECTION ORDERING with /o? url parameter
  // look for query param and set the section order - must contain numbers 0-4 and defaults to the equivalent of: ?o=0,1,2,3,4
  // reorderable sections are 1:1 with jump links in nav
  let referenceOrder = isPricingPage ? defaultPricingOrder : defaultOrder;
  const searchStr = location.search;
  const parsed = queryString.parse(searchStr, {arrayFormat: 'comma'});  
  if (parsed["o"] && parsed["o"].length === referenceOrder.length) {
    order = parsed["o"].map(i => referenceOrder[i]);
  } else {
    order = referenceOrder;
  }

  return (
    <Layout>
      <Seo/>
      <DownloadModal/>
      <Navbar/>
      {isPricingPage && (<Pricing/>)}
      { order.map((component, index) => {
        if(component.props.hasOwnProperty('teamPhotos')) {
            return React.cloneElement(component, { key: index, teamPhotos: data })
        } else {
            return React.cloneElement(component, { key: index })
        }
      })}
      <Footer isIndexPage />
    </Layout>
  )
}

const query = graphql`
  query TeamPhotos {
    olivia: file(relativePath: {regex: "/photo-olivia/"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          quality: 80,
          width: 175,
          height: 175,
          formats: [PNG, WEBP],
          tracedSVGOptions: { background: "#0263C5", color: "#D2DAE4", turdSize: 0 }, 
          transformOptions: {
            duotone: { highlight: "#D2DAE4", shadow: "#0263C5" }
          },
        )
      }
    }
    andres: file(relativePath: {regex: "/photo-andres/"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          quality: 80,
          width: 175,
          height: 175,
          tracedSVGOptions: { background: "#0263C5", color: "#D2DAE4", turdSize: 0 }, 
          transformOptions: {
            duotone: { highlight: "#D2DAE4", shadow: "#0263C5" }
          },
        )
      }
    }
    gabriel: file(relativePath: {regex: "/photo-gabriel/"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          quality: 80,
          width: 175,
          height: 175,
          tracedSVGOptions: { background: "#0263C5", color: "#D2DAE4", turdSize: 0 }, 
          transformOptions: {
            duotone: { highlight: "#D2DAE4", shadow: "#0263C5" }
          },
        )
      }
    }
    bastian: file(relativePath: {regex: "/photo-bastian/"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          quality: 80,
          width: 175,
          height: 175,
          tracedSVGOptions: { background: "#0263C5", color: "#D2DAE4", turdSize: 0 }, 
          transformOptions: {
            duotone: { highlight: "#D2DAE4", shadow: "#0263C5" }
          },
        )
      }
    }
    sergio: file(relativePath: {regex: "/photo-sergio/"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          quality: 80,
          width: 175,
          height: 175,
          tracedSVGOptions: { background: "#0263C5", color: "#D2DAE4", turdSize: 0 }, 
          transformOptions: {
            duotone: { highlight: "#D2DAE4", shadow: "#0263C5" }
          },
        )
      }
    }
    michelle: file(relativePath: {regex: "/photo-michelle/"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          quality: 80,
          width: 175,
          height: 175,
          tracedSVGOptions: { background: "#0263C5", color: "#D2DAE4", turdSize: 0 },
          transformOptions: {
            duotone: { highlight: "#D2DAE4", shadow: "#0263C5" }
          },
        )
      }
    }
    diego: file(relativePath: {regex: "/photo-diego/"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          quality: 100,
          width: 175,
          height: 175,
          tracedSVGOptions: { background: "#0263C5", color: "#D2DAE4", turdSize: 0 },
          transformOptions: {
            duotone: { highlight: "#D2DAE4", shadow: "#0263C5" }
          },
        )
      }
    }
    marcos: file(relativePath: {regex: "/photo-marcos/"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          quality: 80,
          width: 175,
          height: 175,
          tracedSVGOptions: { background: "#0263C5", color: "#D2DAE4", turdSize: 0 },
          transformOptions: {
            duotone: { highlight: "#D2DAE4", shadow: "#0263C5" }
          },
        )
      }
    }
    rafael: file(relativePath: {regex: "/photo-rafael/"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          quality: 80,
          width: 175,
          height: 175,
          tracedSVGOptions: { background: "#0263C5", color: "#D2DAE4", turdSize: 0 },
          transformOptions: {
            duotone: { highlight: "#D2DAE4", shadow: "#0263C5" }
          },
        )
      }
    }
    dennis: file(relativePath: {regex: "/photo-dennis/"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          quality: 80,
          width: 175,
          height: 175,
          tracedSVGOptions: { background: "#0263C5", color: "#D2DAE4", turdSize: 0 },
          transformOptions: {
            duotone: { highlight: "#D2DAE4", shadow: "#0263C5" }
          },
        )
      }
    }
    michael: file(relativePath: {regex: "/photo-michael/"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          quality: 80,
          width: 175,
          height: 175,
          tracedSVGOptions: { background: "#0263C5", color: "#D2DAE4", turdSize: 0 },
          transformOptions: {
            duotone: { highlight: "#D2DAE4", shadow: "#0263C5" }
          },
        )
      }
    }
    daniela: file(relativePath: {regex: "/photo-daniela/"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          quality: 80,
          width: 175,
          height: 175,
          tracedSVGOptions: { background: "#0263C5", color: "#D2DAE4", turdSize: 0 },
          transformOptions: {
            duotone: { highlight: "#D2DAE4", shadow: "#0263C5" }
          },
        )
      }
    }
    roger: file(relativePath: {regex: "/photo-roger/"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          quality: 80,
          width: 175,
          height: 175,
          tracedSVGOptions: { background: "#0263C5", color: "#D2DAE4", turdSize: 0 },
          transformOptions: {
            duotone: { highlight: "#D2DAE4", shadow: "#0263C5" }
          },
        )
      }
    }
    kat: file(relativePath: {regex: "/photo-kat/"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          quality: 80,
          width: 175,
          height: 175,
          tracedSVGOptions: { background: "#0263C5", color: "#D2DAE4", turdSize: 0 },
          transformOptions: {
            duotone: { highlight: "#D2DAE4", shadow: "#0263C5" }
          },
        )
      }
    }
    kai: file(relativePath: {regex: "/photo-kai/"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          quality: 80,
          width: 175,
          height: 175,
          tracedSVGOptions: { background: "#0263C5", color: "#D2DAE4", turdSize: 0 },
          transformOptions: {
            duotone: { highlight: "#D2DAE4", shadow: "#0263C5" }
          },
        )
      }
    }
    samantha: file(relativePath: {regex: "/photo-samantha/"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          quality: 80,
          width: 175,
          height: 175,
          tracedSVGOptions: { background: "#0263C5", color: "#D2DAE4", turdSize: 0 },
          transformOptions: {
            duotone: { highlight: "#D2DAE4", shadow: "#0263C5" }
          },
        )
      }
    }
    gonzalo: file(relativePath: {regex: "/photo-gonzalo/"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          quality: 80,
          width: 175,
          height: 175,
          tracedSVGOptions: { background: "#0263C5", color: "#D2DAE4", turdSize: 0 },
          transformOptions: {
            duotone: { highlight: "#D2DAE4", shadow: "#0263C5" }
          },
        )
      }
    }
    felipe: file(relativePath: {regex: "/photo-felipe/"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          quality: 80,
          width: 175,
          height: 175,
          tracedSVGOptions: { background: "#0263C5", color: "#D2DAE4", turdSize: 0 },
          transformOptions: {
            duotone: { highlight: "#D2DAE4", shadow: "#0263C5" }
          },
        )
      }
    }
    brian: file(relativePath: {regex: "/photo-brian/"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          quality: 80,
          width: 175,
          height: 175,
          tracedSVGOptions: { background: "#0263C5", color: "#D2DAE4", turdSize: 0 },
          transformOptions: {
            duotone: { highlight: "#D2DAE4", shadow: "#0263C5" }
          },
        )
      }
    }
    thomas: file(relativePath: {regex: "/photo-thomas/"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          quality: 80,
          width: 175,
          height: 175,
          tracedSVGOptions: { background: "#0263C5", color: "#D2DAE4", turdSize: 0 },
          transformOptions: {
            duotone: { highlight: "#D2DAE4", shadow: "#0263C5" }
          },
        )
      }
    }
    carlos: file(relativePath: {regex: "/photo-carlos/"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          quality: 80,
          width: 175,
          height: 175,
          tracedSVGOptions: { background: "#0263C5", color: "#D2DAE4", turdSize: 0 },
          transformOptions: {
            duotone: { highlight: "#D2DAE4", shadow: "#0263C5" }
          },
        )
      }
    }
    matt: file(relativePath: {regex: "/photo-matt/"}) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED,
          quality: 80,
          width: 175,
          height: 175,
          tracedSVGOptions: { background: "#0263C5", color: "#D2DAE4", turdSize: 0 },
          transformOptions: {
            duotone: { highlight: "#D2DAE4", shadow: "#0263C5" }
          },
        )
      }
    }
  }
`

export default IndexPage
