import React from "react";

function IconFrequency() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="36"
      fill="none"
      viewBox="0 0 37 36"
    >
      <g clipPath="url(#clip0_359_2366)">
        <g filter="url(#filter0_i_359_2366)">
          <rect
            width="34"
            height="31"
            x="1.252"
            y="3"
            fill="#6AA7F8"
            rx="2"
          ></rect>
        </g>
        <g filter="url(#filter1_i_359_2366)">
          <path
            fill="#6AA7F8"
            d="M3.252 14h30v15a3 3 0 01-3 3h-24a3 3 0 01-3-3V14z"
          ></path>
          <path
            fill="#010912"
            d="M15.08 29.81c1.995 0 3.461-1.114 3.461-2.889 0-1.158-.601-1.994-1.555-2.434v-.044c.778-.484 1.247-1.276 1.247-2.259 0-1.73-1.466-2.713-3.3-2.713-1.496 0-2.728.792-3.168 1.892l1.907 1.012c.234-.514.586-.792 1.188-.792.63 0 1.085.41 1.085 1.026 0 .572-.367.998-1.07.998h-.822v1.848h.66c.91 0 1.364.513 1.364 1.144 0 .733-.557 1.129-1.173 1.129-.777 0-1.232-.367-1.452-1.056l-2.068.821c.484 1.32 1.848 2.318 3.696 2.318zM21.771 29.679h2.406V19.603h-1.892l-3.168 2.244 1.13 1.76 1.524-1.13v7.202z"
          ></path>
        </g>
        <path
          fill="#010912"
          d="M3.252 6a1 1 0 011-1h28a1 1 0 011 1v8h-30V6z"
        ></path>
        <g filter="url(#filter2_i_359_2366)">
          <rect width="4" height="7" x="10.252" fill="#6AA7F8" rx="2"></rect>
        </g>
        <g filter="url(#filter3_i_359_2366)">
          <rect width="4" height="7" x="22.252" fill="#6AA7F8" rx="2"></rect>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i_359_2366"
          width="34"
          height="31"
          x="1.252"
          y="3"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_2366"></feBlend>
        </filter>
        <filter
          id="filter1_i_359_2366"
          width="30"
          height="18"
          x="3.252"
          y="14"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_2366"></feBlend>
        </filter>
        <filter
          id="filter2_i_359_2366"
          width="4"
          height="7"
          x="10.252"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_2366"></feBlend>
        </filter>
        <filter
          id="filter3_i_359_2366"
          width="4"
          height="7"
          x="22.252"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_2366"></feBlend>
        </filter>
        <clipPath id="clip0_359_2366">
          <path
            fill="#fff"
            d="M0 0H36V36H0z"
            transform="translate(.252)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconFrequency;
