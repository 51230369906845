import React from "react";

function IconML() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="36"
      fill="none"
      viewBox="0 0 37 36"
    >
      <g
        strokeWidth="2"
        clipPath="url(#clip0_359_1773)"
        filter="url(#filter0_i_359_1773)"
      >
        <path
          fill="#6AA7F8"
          stroke="#6AA7F8"
          d="M2.252 32V22a2 2 0 012-2h21a2 2 0 012 2v10a2 2 0 01-2 2h-21a2 2 0 01-2-2z"
        ></path>
        <path
          stroke="#6AA7F8"
          d="M25.252 20h7a2 2 0 012 2v10a2 2 0 01-2 2h-7"
        ></path>
        <path
          stroke="#010912"
          strokeLinecap="round"
          d="M4.252 32h2.5M4.252 28h2.5M4.252 24h2.5"
        ></path>
        <path
          fill="#6AA7F8"
          stroke="#6AA7F8"
          d="M2.252 14V5a2 2 0 012-2h21a2 2 0 012 2v9a2 2 0 01-2 2h-21a2 2 0 01-2-2z"
        ></path>
        <path stroke="#010912" strokeLinecap="round" d="M4.252 14h2.5"></path>
        <path
          stroke="#6AA7F8"
          d="M24.252 3h8a2 2 0 012 2v9a2 2 0 01-2 2h-8"
        ></path>
        <path
          stroke="#010912"
          strokeLinecap="round"
          d="M4.252 10h2.5M4.252 6h2.5"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_i_359_1773"
          width="36"
          height="36"
          x="0.252"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0 0.979167 0 0 0 0.48 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_359_1773"></feBlend>
        </filter>
        <clipPath id="clip0_359_1773">
          <path
            fill="#fff"
            d="M0 0H36V36H0z"
            transform="translate(.252)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconML;
