import { themeObject } from "../../../../../global-style";
import { EmilyBudget } from "./budget-graphs";
import { EmilyRecurring } from "./recurring-graphs";

const Emily = {
  "integrations": {
    "amazon": [],
    "venmo": [{
      "category": { "name": "Rent", "color": themeObject.color.categoryViolet, "emoji": "💔" },
      "description": "Rent to Cara Lee",
      "emoji_only_description": false,
      "amount": 750
    }, 
    {
      "category": { "name": "Food", "color": themeObject.color.categoryOrange, "emoji": "🥑" },
      "description": "🍕🍻",
      "emoji_only_description": true,
      "amount": 20
    },{
      "category": { "name": "Lyft", "color": themeObject.color.categoryBlue, "emoji": "💸" },
      "description": "Carshare to Al...",
      "emoji_only_description": false,
      "amount": 20
    }]
  },
  "rules": [{
    "rule": "Sephora",
    "category": { "name": "Retail Therapy", "color": themeObject.color.categoryMagenta, "emoji": "✨" }
  },
  {
    "rule": "Rent to Cara Lee",
    "category": { "name": "Rent", "color": themeObject.color.categoryViolet, "emoji": "💔" }
  },
  {
    "rule": "Nyc Paygo",
    "category": { "name": "Subway", "color": themeObject.color.categoryGreen, "emoji": "🚊" }
  }
  ],
  "budget": {
    "left": 255,
    "total": "1,722",
    "graph": EmilyBudget,
    "budgeting_disabled": false,
  },
  "spending": {
    "status": "2.3",
    "over": false,
    "budgeting_disabled": false,
    "categories": [
      { "name": "Food", "color": themeObject.color.categoryOrange, "emoji": "🥑", "performance": 82 },
      { "name": "Spin class", "color": themeObject.color.categoryRuby, "emoji": "🚴‍♀️", "performance": 70 },
      { "name": "Lyft", "color": themeObject.color.categoryBlue, "emoji": "💸", "performance": 65 },
      { "name": "Subway", "color": themeObject.color.categoryGreen, "emoji": "🚊", "performance": 53 },
      { "name": "Utilities", "color": themeObject.color.categoryOrange, "emoji": "🚿", "performance": 53 },
      { "name": "Rent", "color": themeObject.color.categoryViolet, "emoji": "💔", "performance": 50 },
      { "name": "Outings", "color": themeObject.color.categoryTan, "emoji": "📚", "performance": 46 },
      { "name": "Fun stuff", "color": themeObject.color.categoryGreen, "emoji": "🤪", "performance": 43 },
      { "name": "Retail Therapy", "color": themeObject.color.categoryMagenta, "emoji": "✨", "performance": 3 },
      { "name": "Textbooks", "color": themeObject.color.categoryLime, "emoji": "📚", "performance": 2 },
      { "name": "Entertainment", "color": themeObject.color.categoryLime, "emoji": "🎢", "performance": 2 }
    ]
  },
  "investments": [],
  "recurrings": {
    "left": 0,
    "total": 897,
    "graph": EmilyRecurring,
    "schedule": [
      { "name": "Rent", "emoji": "🏠", "balance": "750", "date": "1st", "paid": true },
      { "name": "Spotify", "emoji": "🎧", "balance": "9.99", "date": "10th", "paid": true },
      { "name": "Ipsy", "emoji": "💄", "balance": "13", "date": "3rd", "paid": true },
      { "name": "Hulu", "emoji": "📺", "balance": "7.99", "date": "15th", "paid": true }
    ]
  }
}

export default Emily;