import React from "react";
// import { motion } from "framer-motion";

const containerStyle = {
  position: "absolute",
  zIndex: -3,
  overflow: "hidden",
  width: "100%",
  height: 820
}
const svgStyle = {
  position: "absolute",
  top: 0,
  left: "50%",
  marginLeft: -1173, /* half of the width */
  height: 820
}
// const motionVariants = {
//   down: { y: 50, opacity: 0 },
//   up: { y: 0, opacity: 1 }
// }

function Starfield(props) {
  return (
    <div 
      style={containerStyle}
      // variants={motionVariants}
      // transition={{ type: 'spring', bounce: 0, duration: 6, delay: 1.5 }}
      // initial="down"
      // animate="up"
      {...props}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="2346"
        height="820"
        fill="none"
        viewBox="0 0 2346 820"
        style={svgStyle}
      >
        <circle cx="1780.5" cy="596.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="1780.5" cy="373.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="1937.5" cy="471.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="2049.5" cy="225.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="2185.5" cy="445.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="2158.5" cy="428.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="2245.5" cy="246.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="2139.5" cy="118.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="2017.5" cy="165.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="1831.5" cy="512.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="1927.5" cy="541.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="524.5" cy="320.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="378.5" cy="416.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="406.5" cy="439.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="505.5" cy="183.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="274.5" cy="111.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="281.5" cy="101.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="396.5" cy="53.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="568.5" cy="91.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="785.5" cy="68.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="130.5" cy="82.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="532.5" cy="429.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="1690.5" cy="455.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="1305.5" cy="472.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="1037.5" cy="401.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="1377.5" cy="366.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="1394.5" cy="374.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="1653.5" cy="106.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="1333.5" cy="162.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="965.5" cy="117.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="1822.5" cy="742.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="1578.5" cy="566.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="1642.5" cy="117.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="837.5" cy="384.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="497.5" cy="172.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="302.5" cy="375.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="206.5" cy="485.5" r="0.5" fill="#6AA7F8"></circle>
        <circle cx="579" cy="586" r="1" fill="#6AA7F8"></circle>
        <circle cx="396" cy="438" r="1" fill="#6AA7F8"></circle>
        <circle cx="405" cy="427" r="1" fill="#6AA7F8"></circle>
        <circle cx="323" cy="111" r="1" fill="#6AA7F8"></circle>
        <circle cx="1713" cy="266" r="1" fill="#6AA7F8"></circle>
        <circle cx="1631" cy="110" r="1" fill="#6AA7F8"></circle>
        <circle cx="1973" cy="277" r="1" fill="#6AA7F8"></circle>
        <circle cx="2082" cy="137" r="1" fill="#6AA7F8"></circle>
        <circle cx="1975" cy="37" r="1" fill="#6AA7F8"></circle>
        <circle cx="1332" cy="101" r="1" fill="#6AA7F8"></circle>
        <circle cx="936" cy="55" r="1" fill="#6AA7F8"></circle>
        <circle cx="215" cy="210" r="1" fill="#6AA7F8"></circle>
        <circle cx="1288" cy="174" r="1" fill="#6AA7F8"></circle>
        <circle cx="2120" cy="311" r="1" fill="#6AA7F8"></circle>
        <circle cx="2130" cy="44" r="1" fill="#6AA7F8"></circle>
        <circle cx="757" cy="108" r="1" fill="#6AA7F8"></circle>
        <circle cx="838" cy="164" r="1" fill="#6AA7F8"></circle>
      </svg>
    </div>
  );
}

export default Starfield;
